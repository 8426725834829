import {AlignRightDiv} from "../layout/FlexDiv";
import Button from "@mui/joy/Button";
import FavoriteIcon from "@mui/icons-material/Favorite";
import useOrganisation from "../../hooks/contexts/OrganisationContext";
import useTranslation from "../../hooks/contexts/TranslationContext";

export default function SubscribeButton() {
    const {userIsSubscribed, orgNavigate} = useOrganisation()
    const {t} = useTranslation()

    if (userIsSubscribed)
        return <></>

    return <>
        <AlignRightDiv>
            <Button onClick={() => orgNavigate("/memberships")} variant={"soft"} size={"lg"}>
                <FavoriteIcon sx={{mr: 1}}/>
                {t("Subscribe")}
            </Button>
        </AlignRightDiv>
    </>
}
