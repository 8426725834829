import {ReactNode} from "react";
import {Grid} from "@mui/joy";
import Typography from "@mui/joy/Typography";

export default function TabSettingLayout(props: { children: ReactNode, question: string | ReactNode }) {
    return <Grid container>
        <Grid md={7}>
            <Typography variant={"soft"} level={"body-lg"} sx={{m: 1}}>{props.question}</Typography>
        </Grid>
        <Grid md={5}>
            {props.children}
        </Grid>
    </Grid>
}
