import {CenterDiv} from "./FlexDiv";
import Box from "@mui/joy/Box";
import TTypography from "./joy/TTypography";
import useOrganisation from "../../hooks/contexts/OrganisationContext";
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';

export default function NoResultsFound({msg = "No results found!"}: { msg?: string }) {
    const {organisation} = useOrganisation()

    return <Box sx={{width: "100%"}}>
        <CenterDiv>
            <Box sx={{m: 2, my: 3, textAlign: "center"}}>
                <SentimentDissatisfiedIcon
                    sx={{fontSize: 128, color: `hsl(${organisation.settings.hslDefaultColor}, 100%, 60%)`}}/>
                <TTypography level={"title-md"}>{msg}</TTypography>
            </Box>
        </CenterDiv>
    </Box>
}