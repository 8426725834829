import {CircularProgress} from "@mui/joy";
import Button from "@mui/joy/Button";

export default function LoadingButton() {
    return <Button disabled>
        <LoadingBtnIcon/>
        Loading...
    </Button>
}

export function LoadingBtnIcon() {
    return <CircularProgress color={"neutral"}
                             sx={{h: "25px", w: "25px", mr: 2}}/>
}