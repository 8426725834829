import useTranslation from "../../../hooks/contexts/TranslationContext";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";

export default function GridInfoItem({title, value}: { title: string, value: string }) {
    const {t} = useTranslation()
    return <Box sx={{m: 2}}>
        <Typography level={"title-sm"}>{t(title)}</Typography>
        <Typography level={"body-md"}>
            {value}
        </Typography>
    </Box>
}
