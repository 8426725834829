import Table from "@mui/joy/Table";
import Tth from "../../../layout/Tth";
import * as React from "react";
import {useState} from "react";
import Membership from "../../../../types/Membership";
import {compare, dateString} from "../../../../utils/date";
import {Accordion, AccordionDetails, AccordionSummary, Sheet} from "@mui/joy";
import useTranslation from "../../../../hooks/contexts/TranslationContext";
import {ModifyIcon} from "../../../layout/CrudIcons";
import IconButton from "@mui/joy/IconButton";
import Modal from "../../../layout/modal/Modal";
import MembershipForm from "./MembershipForm";
import useNotifications from "../../../../hooks/contexts/NotificationContext";
import {putObject} from "../../../../functions/objectService";

export default function ManageMemberships({data, refresh}: { data: Membership[], refresh: VoidFunction }) {
    const {t} = useTranslation()

    if (data.length === 0)
        return <></>

    return <Accordion>
        <AccordionSummary>{t("Current memberships")}</AccordionSummary>
        <AccordionDetails>
            <Sheet>
                <Table>
                    <thead>
                    <tr>
                        <Tth>Title</Tth>
                        <Tth>Starts</Tth>
                        <Tth>Expires</Tth>
                        <Tth>Member limit</Tth>
                        <Tth>Price</Tth>
                        <Tth>Points</Tth>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {data
                        .sort((a, b) => compare(b.expiry, a.expiry))
                        .map(m => <MembershipRow refresh={refresh} key={m.id} m={m}/>)}
                    </tbody>
                </Table>
            </Sheet>
        </AccordionDetails>
    </Accordion>
}

function MembershipRow({m, refresh}: { m: Membership, refresh: VoidFunction }) {
    const {t} = useTranslation()
    const [isEditing, setIsEditing] = useState(false)
    const {success, error} = useNotifications()

    async function handleSave(e: Membership) {
        try {
            success(t("Successfully saved membership"))
            await putObject("/Organisations/Memberships", e)
            setIsEditing(false)
            refresh()
        } catch (e: any) {
            error(e)
        }
    }

    return <>
        <Modal title={`${t("Modify")} ${m.title}`} open={isEditing} onClose={() => setIsEditing(false)}>
            <MembershipForm m={m} handleSubmit={handleSave}/>
        </Modal>
        <tr>
            <td>
                {m.title}
            </td>
            <td>
                {dateString(m.start)}
            </td>
            <td>
                {dateString(m.expiry)}
            </td>
            <td>
                {m.memberLimit > 0 ? m.memberLimit : t("Infinite")}
            </td>
            <td>
                {m.feeToJoin > 0 ? `€ ${m.pointsForBuying}` : t("Free")}
            </td>
            <td>
                {m.pointsForBuying > 0 ? m.pointsForBuying : "/"}
            </td>
            <td>
                <IconButton onClick={() => setIsEditing(true)} color={"warning"}>
                    <ModifyIcon/>
                </IconButton>
            </td>
        </tr>
    </>
}
