import ClubEvent from "../../../types/ClubEvent";
import {useGetCollection} from "../../../hooks/useHttp";
import useOrganisation from "../../../hooks/contexts/OrganisationContext";
import {LinearProgress} from "@mui/joy";
import ClubEventBannerCard from "../../../components/particles/ClubEventBannerCard";
import useInterval from "../../../hooks/useInterval";
import PageTitle from "../../../components/layout/joy/PageTitle";
import useTranslation from "../../../hooks/contexts/TranslationContext";

export default function EventsList() {
    const {ORG_URI, isOrgAdmin} = useOrganisation()
    const {
        data: upcomingEvents,
        refresh: refreshUpcomingEvents,
        firstLoading: loadingUpcomingEvents,
        error: errorUpcomingEvents
    } = useGetCollection<ClubEvent>(`${ORG_URI}/Events/Upcoming`)
    const {
        data: participatedEvents,
        refresh: refreshParticipatedEvents,
        firstLoading: loadingParticipatedEvents,
        error: errorParticipatedEvents
    } = useGetCollection<ClubEvent>(`${ORG_URI}/Events/Participated`)
    useInterval(refreshUpcomingEvents, 90)
    useInterval(refreshParticipatedEvents, 120)

    const allHasLoaded = loadingParticipatedEvents && loadingUpcomingEvents
    const totalEventsCount = upcomingEvents.length + participatedEvents.length

    return <>
        {totalEventsCount === 0 && !allHasLoaded &&
            <PageTitle>It looks like this organisation has no events!</PageTitle>}
        {!errorUpcomingEvents &&
            <EventSection loading={loadingUpcomingEvents} title={"Upcoming events"} titleSingular={"Upcoming event"} events={upcomingEvents}/>}
        {!errorParticipatedEvents && !loadingParticipatedEvents &&
            <EventSection loading={loadingParticipatedEvents} title={"Events you participated in"}
                          events={participatedEvents}/>}
        {isOrgAdmin && <DraftEvents/>}
    </>
}

function DraftEvents() {
    const {ORG_URI} = useOrganisation()
    const {
        data: events,
        refresh,
        firstLoading,
        error
    } = useGetCollection<ClubEvent>(`${ORG_URI}/Events/Draft`)
    useInterval(refresh, 130)

    if (error)
        return <></>

    return <EventSection events={events} title={"Draft events"} loading={firstLoading}/>
}

function EventSection({events, title, titleSingular, loading}: { events: ClubEvent[], title: string, titleSingular?:string, loading: boolean }) {
    const {t} = useTranslation()
    let useTitle = title

    if (events.length === 1 && titleSingular && titleSingular.length > 0)
        useTitle = titleSingular

    if (!loading && events.length === 0) return <></>
    return <section className={"events_participated"}>
        <PageTitle>{t(useTitle)}</PageTitle>
        {loading
            ? <LinearProgress sx={{my: 2}}/>
            : <div className={"flexElements"} style={{maxWidth: "100%"}}>
                {events
                    .sort((a, b) => new Date(a.startEvent).getTime() - new Date(b.startEvent).getTime())
                    .map(e => <ClubEventBannerCard key={e.id} event={e}/>)}
            </div>}
    </section>
}
