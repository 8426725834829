import {Container, Grid} from "@mui/joy";
import EventTypeForm from "./Form";
import useOrganisation from "../../../../hooks/contexts/OrganisationContext";
import {ClubEventType, sortEventTypeAsc} from "../../../../types/ClubEvent";
import useNotifications from "../../../../hooks/contexts/NotificationContext";
import Typography from "@mui/joy/Typography";
import {useGetCollection} from "../../../../hooks/useHttp";
import LoadingPage from "../../../LoadingPage";
import Error500 from "../../../errors/Error500";
import Box from "@mui/joy/Box";
import {useForm} from "react-hook-form";
import InputControl from "../../../../components/form/InputControl";
import {postObject, putObject} from "../../../../functions/objectService";

export default function Create() {
    const {organisation} = useOrganisation()
    const {success, error} = useNotifications()
    const {
        data: eventTypes,
        firstLoading: loadingTypes,
        error: typesError,
        refresh
    } = useGetCollection<ClubEventType>(`/Organisations/${organisation.id}/Events/Types`)

    if (loadingTypes) return <LoadingPage/>
    if (typesError) return <Error500/>

    function onSubmit(element: ClubEventType) {
        postObject(`/Organisations/${organisation.id}/Events/Types/Create`, {entity: element})
            .then(() => {
                success("Successfully created type")
                refresh()
            })
            .catch(err => error(`${err}`))
    }

    return <Container>
        <Grid container spacing={5}>
            <Grid lg={6}>
                <Typography level={"h3"}>Create type</Typography>
                <EventTypeForm onSubmit={onSubmit}
                               eventType={{id: null, name: '', pointsOnRegister: 5, pointsOnParticipate: 1}}/>
            </Grid>
            <Grid lg={6}>
                <>
                    <Typography level={"h3"}>Available types</Typography>
                    {eventTypes.sort(sortEventTypeAsc).map(et =>
                        <EditTypeForm key={et.id} eventType={et}/>)}
                </>
            </Grid>
        </Grid>
    </Container>
}

function EditTypeForm({eventType}: { eventType: ClubEventType }) {
    const {success, error} = useNotifications()
    const {organisation} = useOrganisation()
    const {register, handleSubmit, getValues} = useForm<ClubEventType>()

    function handleSave(element: ClubEventType) {
        if (getValues("pointsOnRegister") < 0 || getValues("pointsOnParticipate") < 0) {
            error("Integer must be positive or 0")
            return;
        }
        putObject(`/Organisations/${organisation.id}/Events/Types/Update`, {entity: {...eventType, ...element}})
            .then(() => {
                success("Successfully created type")
            })
            .catch(err => error(`${err}`))
    }

    return <Box sx={{my: 2}}>
        <Typography level={"title-md"}>{eventType.name}</Typography>
        <Grid container>
            <Grid md={6}>
                <InputControl label={"Points on register"}
                              inputProps={{type: "number", "aria-valuemin": 0}}
                              register={register("pointsOnRegister", {
                                  value: eventType.pointsOnRegister,
                                  onChange: handleSubmit(handleSave),
                              })}/>
            </Grid>
            <Grid md={6}>
                <InputControl label={"Points on participate"}
                              inputProps={{type: "number"}}
                              register={register("pointsOnParticipate", {
                                  value: eventType.pointsOnParticipate,
                                  onChange: handleSubmit(handleSave),
                              })}/>
            </Grid>
        </Grid>
    </Box>
}
