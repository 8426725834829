import {Entity} from "./entity";
import {bytesForHuman} from "../utils/number";

export interface CloudFile extends Entity {
    name: string
    type: string
    fileSize: number
}

export function filterCloudFile(val: CloudFile, filter: string) {
    if (`${val.name}`.toLowerCase().includes(filter.toLowerCase())) return true
    if (`${val.type}`.toLowerCase().includes(filter.toLowerCase())) return true
    if (`${bytesForHuman(val.fileSize)}`.toLowerCase().includes(filter.toLowerCase())) return true
    return `${val.fileSize}`.toLowerCase().includes(filter.toLowerCase());

}
