import Box from "@mui/joy/Box";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Link from "@mui/joy/Link";
import Button from "@mui/joy/Button";
import {useAuth} from "../../hooks/contexts/Auth";
import NotLoggedInLayout from "../../components/layout/NotLoggedInLayout";
import {useNavigate} from "react-router-dom";
import useTranslation from "../../hooks/contexts/TranslationContext";
import {useState} from "react";
import LoadingButton from "../../components/layout/form/LoadingButton";
import useNotifications from "../../hooks/contexts/NotificationContext";

export default function Login() {
    const {login} = useAuth()
    const navigate = useNavigate()
    const {t} = useTranslation()
    const {error} = useNotifications()
    const [loading, setLoading] = useState(false)

    function handleSubmit(event: any) {
        try {
            event.preventDefault();
            setLoading(true)
            const formElements = event.currentTarget.elements;
            login(formElements.email.value, formElements.password.value)
        } catch (e: any) {
            error(e)
        } finally {
            setLoading(false)
        }
    }

    return (
        <NotLoggedInLayout title={"Sign in to your account"} subTitle={"Welcome back"}>
            <form
                onSubmit={handleSubmit}
            >
                <FormControl required>
                    <FormLabel>{t("Email")}</FormLabel>
                    <Input type="email" name="email"/>
                </FormControl>
                <FormControl required>
                    <FormLabel>{t("Password")}</FormLabel>
                    <Input type="password" name="password"/>
                </FormControl>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Link fontSize="sm" onClick={() => navigate("/register")} fontWeight="lg" color={"neutral"}>
                        {t("Register")}
                    </Link>
                    <Link fontSize="sm" onClick={() => navigate("/forgot-password")} fontWeight="lg" color={"neutral"}>
                        {t("Forgot your password?")}
                    </Link>
                </Box>
                {loading
                    ? <LoadingButton/>
                    : <Button type="submit" fullWidth color={"neutral"}>
                        {t("Sign in")}
                    </Button>}

            </form>
        </NotLoggedInLayout>
    );
}
