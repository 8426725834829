import ClubEvent from "../../types/ClubEvent";
import {Card, CardContent, CardCover} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import useOrganisation from "../../hooks/contexts/OrganisationContext";
import {getHslFromOrg, useBanner} from "../../hooks/useBanner";
import {SpaceBetweenDiv} from "../layout/FlexDiv";
import {dateTimeString} from "../../utils/date";

export default function ClubEventBannerCard({event}: { event: ClubEvent }) {
    const {orgNavigate, organisation} = useOrganisation()
    const banner = useBanner(event, organisation)

    const cardStyle = {width: '100%'}

    return <Card
        sx={{
            ...cardStyle, height: 220, m: 1,
            boxShadow: 'md', cursor: 'pointer', userSelect: 'none'
        }}
        onClick={() => orgNavigate(`/events/${event.id}`)}>
        <CardCover sx={!banner ? {background: getHslFromOrg(organisation)} : {}}>
            {banner && <img
                src={banner}
                loading="lazy"
                alt={`banner-event-${event.id}`}
            />}
        </CardCover>
        <CardCover
            sx={{
                background:
                    'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
            }}
        />
        <CardContent sx={{justifyContent: 'flex-end'}}>
            <Typography level="h2" textColor="#fff">
                {event.title}
            </Typography>
            <SpaceBetweenDiv>
                <Typography level="title-sm" textColor="#fff">
                    {event.fee > 0 ? `€${event.fee}` : 'Free'}
                </Typography>
                <Typography level="title-sm" textColor="#fff">
                    {dateTimeString(event.startEvent)}
                </Typography>
            </SpaceBetweenDiv>
        </CardContent>
    </Card>
}
