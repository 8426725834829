import useTranslation from "../../../../hooks/contexts/TranslationContext";
import * as React from "react";
import Membership from "../../../../types/Membership";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/joy";
import moment from "moment/moment";
import {EmptyGuid} from "../../../../utils/string";
import useNotifications from "../../../../hooks/contexts/NotificationContext";
import {postObject} from "../../../../functions/objectService";
import useOrganisation from "../../../../hooks/contexts/OrganisationContext";
import MembershipForm from "./MembershipForm";

const defaultMembershipFormValue = {
    title: '',
    expiry: moment(new Date()).add(1, "years").set("month", 6).set("day", 1).toDate(),
    feeToJoin: 0,
    id: EmptyGuid,
    memberLimit: 0,
    organisation: null,
    description: '',
    pointsForBuying: 0,
    start: moment(new Date()).add(1, "month").set("month", 7).set("day", 1).toDate()
}
export default function CreateMembershipForm({refresh}: { refresh: VoidFunction }) {
    const {t} = useTranslation()
    const {error, success} = useNotifications()
    const {ORG_URI} = useOrganisation()

    async function handleSubmit(m: Membership) {
        try {
            await postObject(`${ORG_URI}/Memberships`, m)
            success(t("Successfully created membership"))
            refresh()
        } catch (e: any) {
            error(e)
        }
    }

    return <Accordion>
        <AccordionSummary>{t("Create membership")}</AccordionSummary>
        <AccordionDetails>
            <MembershipForm m={defaultMembershipFormValue} handleSubmit={handleSubmit}/>
        </AccordionDetails>
    </Accordion>
}