import axios, {AxiosResponse} from "axios"

axios.defaults.baseURL = `https://server.supportersclub.net`
if (process.env?.REACT_APP_PULL_REQUEST_ID && !Number.isNaN(Number.parseInt(process.env?.REACT_APP_PULL_REQUEST_ID))) {
    const backendUrl = `https://${process.env.REACT_APP_PULL_REQUEST_ID}.server.supportersclub.net`
    console.log("Using preview backend:", backendUrl)
    axios.defaults.baseURL = backendUrl
} else if (window.location.host.includes("localhost"))
    axios.defaults.baseURL = "http://localhost:5108"

axios.interceptors.request.use(function (conf) {
    conf.withCredentials = true
    return conf
})

export async function getObject<T>(path: string) {
    try {
        return await axios.get<T>(path)
    } catch (e) {
        throw e
    }

}

export function asEntity<T>(entity: T) {
    return {entity}
}

export async function putObject<T>(path: string, entity: T) {
    return await axios.put<T>(path, entity)
}

export async function putObjectWithResponse<T, R>(path: string, entity: T) {
    return await axios.put<T, AxiosResponse<R, any>>(path, entity)
}

export async function postObject<T>(path: string, entity: T) {
    return await axios.post<T>(path, entity)
}

export async function postFile<T>(path: string, file: T) {
    return await axios.post<T>(path, {
        file
    }, {
        headers: {'Content-Type': 'multipart/form-data'}
    })
}

export async function postEntity<T>(path: string, entity: T) {
    return await postObject(path, asEntity(entity))
}

export async function postEntityWithResponse<T, R>(path: string, entity: T) {
    return await postObjectWithResponse<{ entity: T }, R>(path, asEntity(entity))
}

export async function postObjectWithResponse<T, R>(path: string, entity: T) {
    return await axios.post<T, AxiosResponse<R, any>>(path, entity)
}

export async function deleteObject<T>(path: string) {
    return await axios.delete<T>(path)
}
