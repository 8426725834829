import IosSwitch from "./IosSwitch";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import {FormHelperText} from "@mui/joy";
import useTranslation from "../../hooks/contexts/TranslationContext";

export default function IosSwitchControl({checked, setChecked, label, subLabel, disabled = false}: {
    label?: string | undefined,
    checked: boolean,
    disabled?: boolean,
    setChecked: (val: boolean) => void,
    subLabel?: undefined | string
}) {
    const {t} = useTranslation()

    return <FormControl
        orientation="horizontal"
        sx={{width: '100%', justifyContent: 'space-between', my: 2}}
    >
        <div>
            {label && <FormLabel>{t(label)}</FormLabel>}
            {subLabel && <FormHelperText sx={{mt: 0}}>{t(subLabel)}</FormHelperText>}
        </div>
        <IosSwitch
            disabled={disabled}
            checked={checked}
            setChecked={setChecked}
        />
    </FormControl>
}
