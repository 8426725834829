import Box from "@mui/joy/Box";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Link from "@mui/joy/Link";
import Button from "@mui/joy/Button";
import {useAuth} from "../../hooks/contexts/Auth";
import {ProjectTitle} from "../../utils/globals";
import useNotifications from "../../hooks/contexts/NotificationContext";
import NotLoggedInLayout from "../../components/layout/NotLoggedInLayout";
import {useNavigate} from "react-router-dom";

export default function Register() {
    const {register} = useAuth()
    const {error} = useNotifications()
    const navigate = useNavigate()

    return (
        <NotLoggedInLayout title={"Create your account"} subTitle={`Welcome to ${ProjectTitle}!`}>
            <form
                onSubmit={async (event: any) => {
                    event.preventDefault();
                    const formElements = event.currentTarget.elements;
                    if (formElements.password.value === formElements.password2.value)
                        register(formElements.email.value,
                            formElements.password.value,
                            formElements.firstName.value,
                            formElements.lastName.value,
                            formElements.insuranceNumber.value)
                    else error("Passwords do not match")
                }}
            >
                <FormControl required>
                    <FormLabel>First name</FormLabel>
                    <Input type="text" name="firstName"/>
                </FormControl>
                <FormControl required>
                    <FormLabel>Last name</FormLabel>
                    <Input type="text" name="lastName"/>
                </FormControl>
                <FormControl required>
                    <FormLabel>Email</FormLabel>
                    <Input type="email" name="email"/>
                </FormControl>
                <FormControl required>
                    <FormLabel>National insurance number (example: 00.01.01-123.45)</FormLabel>
                    <Input placeholder={"YY.MM.DD-XXX.XX"} type="text" name="insuranceNumber"/>
                </FormControl>
                <FormControl required>
                    <FormLabel>Password</FormLabel>
                    <Input type="password" name="password"/>
                </FormControl>
                <FormControl required>
                    <FormLabel>Repeat Password</FormLabel>
                    <Input type="password" name="password2"/>
                </FormControl>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Link fontSize="sm" onClick={() => navigate("/login")} fontWeight="lg" color={"neutral"}>
                        Already have an account?
                    </Link>
                </Box>
                <Button type="submit" fullWidth color={"neutral"}>
                    Register
                </Button>
            </form>
        </NotLoggedInLayout>
    );
}
