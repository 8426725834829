import AccordionGroup from "@mui/joy/AccordionGroup";
import * as React from "react";
import CreateMembershipForm from "./MembershipSettings/CreateMembershipForm";
import ManageMemberships from "./MembershipSettings/ManageMemberships";
import useOrganisation from "../../../hooks/contexts/OrganisationContext";
import {useGetCollection} from "../../../hooks/useHttp";
import Membership from "../../../types/Membership";
import LoadingPage from "../../../pages/LoadingPage";
import ManageUserMemberships from "./MembershipSettings/ManageUserMemberships";

export default function MembershipsSettings() {
    const {ORG_URI} = useOrganisation()
    const {data, firstLoading, refresh} = useGetCollection<Membership>(`${ORG_URI}/Memberships/All`)

    if (firstLoading)
        return <LoadingPage/>

    return <AccordionGroup>
        <CreateMembershipForm refresh={refresh}/>
        <ManageMemberships refresh={refresh} data={data}/>
        <ManageUserMemberships/>
    </AccordionGroup>
}

