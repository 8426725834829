import {CircularProgress} from "@mui/joy";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import useTranslation from "../hooks/contexts/TranslationContext";

export default function LoadingPage({title}: { title?: string }) {
    const {t} = useTranslation();

    return <Box className={"flexElements centerElements verticalAlignCenter"}>
        <CircularProgress variant="plain" size={"lg"} sx={{m: 5}}/>
        {title && <Typography className={"loading-dots"} level={"title-md"}>
            {t(title)}
        </Typography>}
    </Box>
}
