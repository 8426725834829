import {useParams} from "react-router-dom";
import Error404 from "../../errors/Error404";
import useGetSingle from "../../../hooks/useHttp";
import {MemberInfo} from "../../../types/ApplicationUser";
import useOrganisation from "../../../hooks/contexts/OrganisationContext";
import Error500 from "../../errors/Error500";
import {Container} from "@mui/joy";
import LoadingPage from "../../LoadingPage";
import ManageUserData from "../../../components/organisation/members/ManageUserDataSection";
import BigOrgUserCard from "../../../components/account/BigOrgUserCard";

export default function Manage() {
    const {userId} = useParams<{ userId: string }>()
    const {ORG_URI, organisation} = useOrganisation()
    const {
        data: user,
        firstLoading,
        error,
    } = useGetSingle<MemberInfo | undefined>(`${ORG_URI}/Members/${userId}`, undefined)

    if (!userId) return <Error404/>
    if (error) return <Error500/>
    if (firstLoading) return <LoadingPage title={"Loading member info"}/>
    if (!user) return <Error404/>

    return <Container>
        <BigOrgUserCard user={user} orgName={organisation.name}/>
        <br/>
        <ManageUserData/>
    </Container>
}

