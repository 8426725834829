import Membership from "../../../../types/Membership";
import {FormHelperText, Grid} from "@mui/joy";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import {toInputDateFormat} from "../../../../utils/date";
import RichEditor from "../../../form/RichEditor";
import {AlignRightDiv} from "../../../layout/FlexDiv";
import Button from "@mui/joy/Button";
import * as React from "react";
import {useState} from "react";
import useTranslation from "../../../../hooks/contexts/TranslationContext";
import {EmptyGuid} from "../../../../utils/string";
import moment from "moment";
import useOrganisation from "../../../../hooks/contexts/OrganisationContext";

export default function MembershipForm({m, handleSubmit}: { m: Membership, handleSubmit: (e: Membership) => void }) {
    const {t} = useTranslation()
    const {organisation} = useOrganisation()
    const [entity, setEntity] = useState<Membership>(m)

    function handleChange(key: string, value: string | number | Date) {
        setEntity({...entity, [key]: value})
    }

    return <>
        <Grid spacing={2} container>
            <Grid md={12}>
                <FormControl>
                    <FormLabel>{t("Title")}</FormLabel>
                    <Input
                        placeholder={t("Title")}
                        onChange={(e) => handleChange("title", `${e.target.value}`)}
                        value={entity.title}/>
                </FormControl>
            </Grid>
            <Grid lg={3} md={6}>
                <FormControl>
                    <FormLabel>{t("Starts")}</FormLabel>
                    <Input
                        type={"date"}
                        onChange={(e) => handleChange("start", moment(e.target.value).utcOffset(organisation.getSafeTimezoneId).toDate())}
                        value={toInputDateFormat(entity.start)}/>
                    <FormHelperText>{t("First day of season")}</FormHelperText>
                </FormControl>
            </Grid>
            <Grid lg={3} md={6}>
                <FormControl>
                    <FormLabel>{t("Expires")}</FormLabel>
                    <Input
                        type={"date"}
                        onChange={(e) => handleChange("expiry", moment(e.target.value).utcOffset(organisation.getSafeTimezoneId).toDate())}
                        value={toInputDateFormat(entity.expiry)}/>
                    <FormHelperText>{t("Last day of season")}</FormHelperText>
                </FormControl>
            </Grid>
            <Grid lg={2} md={4}>
                <FormControl>
                    <FormLabel>{t("Fee to join")}</FormLabel>
                    <Input
                        type={"number"}
                        aria-valuemin={0}
                        placeholder={t("Fee")}
                        onChange={(e) => handleChange("feeToJoin", Number.parseFloat(e.target.value))}
                        value={entity.feeToJoin}/>
                    <FormHelperText>{t("Set 0 for free")}</FormHelperText>
                </FormControl>
            </Grid>
            <Grid lg={2} md={4}>
                <FormControl>
                    <FormLabel>{t("Points for buying")}</FormLabel>
                    <Input
                        type={"number"}
                        aria-valuemin={0}
                        placeholder={t("Points for buying")}
                        onChange={(e) => handleChange("pointsForBuying", Number.parseInt(e.target.value))}
                        value={entity.pointsForBuying}/>
                </FormControl>
            </Grid>
            <Grid lg={2} md={4}>
                <FormControl>
                    <FormLabel>{t("Limit of members")}</FormLabel>
                    <Input
                        type={"number"}
                        aria-valuemin={0}
                        placeholder={t("Limit of members")}
                        onChange={(e) => handleChange("memberLimit", Number.parseInt(e.target.value))}
                        value={entity.memberLimit}/>
                    <FormHelperText>{t("Set 0 for infinite")}</FormHelperText>
                </FormControl>
            </Grid>
            <Grid md={12}>
                <FormControl>
                    <FormLabel>{t("Description")}</FormLabel>
                    <RichEditor value={entity.description}/>
                </FormControl>
            </Grid>
        </Grid>
        <AlignRightDiv sx={{mt: 1}}>
            <Button color={"danger"}
                    sx={{mx: 1}}
                    onClick={() => setEntity(m)}>
                {t("Reset")}
            </Button>
            <Button color={"success"}
                    sx={{mx: 1}}
                    onClick={() => handleSubmit(entity)}>
                {t(EmptyGuid === m.id ? "Create" : "Modify")}
            </Button>
        </AlignRightDiv>
    </>
}
