import {Grid} from "@mui/joy";
import DefaultPageLayout from "../../components/layout/DefaultPageLayout";
import Typography from "@mui/joy/Typography";

export default function Error500() {
    return <Grid container justifyContent="center">
        <DefaultPageLayout title={"Error 500"}>
            <Typography level={"body-lg"}>It looks like we can't connect you to our servers...</Typography>
        </DefaultPageLayout>
    </Grid>

}
