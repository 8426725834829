import {OrganisationProvider} from "../hooks/contexts/OrganisationContext";
import OrganisationPageLayout from "../components/layout/OrganisationPageLayout";
import {Route, Routes} from "react-router-dom";
import EventDetails from "../pages/organisation/event/Details";
import ExampleTables from "../pages/examples/Tables";
import Error404 from "../pages/errors/Error404";
import OrgSettings from "../pages/organisation/Settings";
import Home from "../pages/organisation/Home";
import CreateType from "../pages/organisation/event/type/Create";
import ModifyEvent from "../pages/organisation/event/Modify";
import {ClubEventProvider} from "../hooks/contexts/ClubEventContext";
import Tickets from "../pages/organisation/account/Tickets";
import Memberships from "../pages/organisation/Memberships";
import List from "../pages/organisation/members/List";
import Manage from "../pages/organisation/members/Manage";
import Payment from "../pages/payment/Payment";
import {Container} from "@mui/joy";

export function OrganisationRouter() {
    return <OrganisationProvider>
        <OrganisationPageLayout>
            <Container>
                <Routes>
                    <Route index element={<Home/>}/>
                    <Route path={"/memberships"} element={<Memberships/>}/>
                    <Route path={"/members"} element={<List/>}/>
                    <Route path={"/members/:userId"} element={<Manage/>}/>
                    <Route path={"/settings/"} element={<OrgSettings/>}/>
                    <Route path={"/account/tickets"} element={<Tickets/>}/>
                    <Route path={"/payment/:state/:description"} element={<Payment/>}/>
                    <Route path={"/payment/:state"} element={<Payment/>}/>
                    <Route path={"/events/types"} element={<CreateType/>}/>
                    <Route path={"/events/:clubEventId/manage"}
                           element={<ClubEventProvider><ModifyEvent/></ClubEventProvider>}/>
                    <Route path={"/events/:clubEventId"} element={<EventDetails/>}/>
                    <Route path={"/examples"} element={<ExampleTables/>}/>
                    <Route path={"/*"} element={<Error404/>}/>
                </Routes>
            </Container>
        </OrganisationPageLayout>
    </OrganisationProvider>
}
