// noinspection SpellCheckingInspection

import {CaseInsensitiveMap} from "../types/CaseInsensitiveMap";

export const TranslationsFR = new CaseInsensitiveMap<string, string>();

TranslationsFR.set(
    'Create organisation',
    'Créer une organisation'
)
