import * as React from "react";
import {ReactNode} from "react";
import Box from "@mui/joy/Box";
import TTypography from "./joy/TTypography";

export default function DefaultPageLayout(props: { title: string, children: ReactNode }) {
    return <div>
        <Box
            sx={{
                display: 'flex',
                my: 1,
                gap: 1,
                flexDirection: {xs: 'column', sm: 'row'},
                alignItems: {xs: 'start', sm: 'center'},
                flexWrap: 'wrap',
                justifyContent: 'space-between',
            }}
        >
            {props.title && <TTypography level="h2">{props.title}</TTypography>}
        </Box>
        {props.children}
    </div>
}
