import React, {createContext, useContext, useEffect} from "react";
import Organisation, {getThemeForOrganisation} from "../../types/Organisation";
import useGetSingle from "../useHttp";
import {useNavigate, useParams} from "react-router-dom";
import useInterval from "../useInterval";
import Error500 from "../../pages/errors/Error500";
import LoadingPage from "../../pages/LoadingPage";
import {CssVarsProvider} from "@mui/joy/styles";
import Error404 from "../../pages/errors/Error404";
import {INTERVALS, ProjectTitle} from "../../utils/globals";
import {useAuth} from "./Auth";
import GreyTheme from "../../components/layout/GreyTheme";
import useFavicon from "../useFavicon";

const OrganisationContext = createContext<{
    organisation: Organisation,
    orgNavigate: (val: string) => void,
    refresh: () => void,
    ORG_URI: string
    isOrgAdmin: boolean
    isOrgOwner: boolean
    userIsSubscribed: boolean
}>({
    organisation: {} as Organisation,
    orgNavigate: () => {
    },
    ORG_URI: '',
    refresh: () => {
    },
    isOrgAdmin: false,
    isOrgOwner: false,
    userIsSubscribed: false
})

export default function useOrganisation() {
    return useContext(OrganisationContext)
}

export function OrganisationProvider(props: { children: React.ReactNode }) {
    const {organisationUri} = useParams<{ organisationUri: string }>()
    const {
        refresh,
        firstLoading,
        data: organisation,
        error,
        errorMsg,
    } = useGetSingle<Organisation | null>(`/organisations/get/${organisationUri}`, null)
    const {user} = useAuth()
    useInterval(refresh, INTERVALS.OrganisationRefresh)
    const navigate = useNavigate()
    useFavicon(organisation?.logo)

    useEffect(() => {
        document.title = organisation?.name ?? ProjectTitle
    }, [organisation?.name])

    const orgNavigate = (val: string) => {
        navigate(`/${organisationUri}${val}`)
    }

    if (firstLoading)
        return <GreyTheme><LoadingPage title={"Loading organisation"}/></GreyTheme>

    if (errorMsg.includes("404"))
        return <GreyTheme><Error404/></GreyTheme>

    if (error || !organisation?.settings || organisation === null)
        return <GreyTheme><Error500/></GreyTheme>

    const ORG_URI = `/Organisations/${organisation?.id}`

    const isOrgOwner = user?.id === organisation.owner?.id
    const isOrgMod = user !== null && ([] as string[]).includes(user.id)
    const isOrgAdmin = isOrgMod || isOrgOwner
    const userIsSubscribed = (user?.following ?? []).filter(o => o.id === organisation.id).length > 0 || isOrgAdmin

    return <OrganisationContext.Provider
        value={{organisation, orgNavigate, ORG_URI, refresh, isOrgAdmin, isOrgOwner, userIsSubscribed}}>
        <CssVarsProvider theme={getThemeForOrganisation(organisation?.settings)}>
            {props.children}
        </CssVarsProvider>
    </OrganisationContext.Provider>
}
