import {useEffect} from "react";
import {ProjectTitle} from "../../utils/globals";
import useFavicon from "../../hooks/useFavicon";

export default function SetPublicPageInfo() {
    useEffect(() => {
        document.title = ProjectTitle
    })
    useFavicon()

    return <></>
}