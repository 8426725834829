import ReactQuill, {ReactQuillProps} from "react-quill";
import 'react-quill/dist/quill.snow.css';
import {Sheet} from "@mui/joy";

export default function RichEditor(props: ReactQuillProps) {
    return <Sheet>
        <ReactQuill modules={{
            toolbar: [
                ['bold', 'italic', 'underline', 'strike', {'color': []}, {'background': []}, {'font': []}, {'size': ['small', false, 'large', 'huge']}],
                ['link', 'image'],
                [{'list': 'ordered'}, {'list': 'bullet'}],
                [{'align': []}],
                ['blockquote'],
                ['clean']
            ]
        }} theme="snow" {...props} />
    </Sheet>
}

export function anyText(content: string) {
    return content.length > 30
}