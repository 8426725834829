import useOrganisation from "../../hooks/contexts/OrganisationContext";
import {useEffect, useState} from "react";
import {useGetCollection} from "../../hooks/useHttp";
import Membership, {UserMembership} from "../../types/Membership";
import Error500 from "../errors/Error500";
import LoadingPage from "../LoadingPage";
import PageTitle from "../../components/layout/joy/PageTitle";
import {SpaceBetweenDiv} from "../../components/layout/FlexDiv";
import Card from "@mui/joy/Card";
import {Alert, CardActions, CardContent, Grid} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import useTranslation from "../../hooks/contexts/TranslationContext";
import {dateString} from "../../utils/date";
import TTypography from "../../components/layout/joy/TTypography";
import Button from "@mui/joy/Button";
import {postObject, postObjectWithResponse} from "../../functions/objectService";
import {useAuth} from "../../hooks/contexts/Auth";
import PaymentButton from "../../components/form/PaymentButton";
import {EntityPaymentRequest} from "../../types/Transaction";
import {EmptyGuid} from "../../utils/string";
import useNotifications from "../../hooks/contexts/NotificationContext";

export default function Memberships() {
    const {userIsSubscribed, orgNavigate, ORG_URI} = useOrganisation()
    const {
        data: memberships,
        firstLoading: loadingMemberships,
        error: errorMemberships,
        refresh: refreshMemberships,
    } = useGetCollection<Membership>(`${ORG_URI}/Memberships`)
    const {
        data: userMemberships,
        firstLoading: loadingUserMemberships,
        error: errorUserMemberships,
        refresh: refreshUserMemberships,
    } = useGetCollection<UserMembership>(`${ORG_URI}/UserMemberships/User`)
    const [requestedAny, setRequestedAny] = useState(false)
    const {t} = useTranslation()

    useEffect(() => {
        if (userIsSubscribed)
            orgNavigate("")
    }, [orgNavigate, userIsSubscribed]);

    if (loadingMemberships || loadingUserMemberships)
        return <LoadingPage/>
    if (errorMemberships || errorUserMemberships)
        return <Error500/>

    return <>
        <PageTitle>Memberships</PageTitle>
        {!requestedAny &&
            <TTypography level={"title-md"}>A membership is required to buy tickets for events.</TTypography>}
        {requestedAny && <Alert variant={"soft"} color={"warning"} sx={{mb: 1}}>
            <strong>{t("Warning!")}</strong>
            Additional steps may be necessary!
        </Alert>}
        <Grid spacing={2} container>
            {memberships
                .map(m => <Grid key={m.id} xs={12} sm={6} md={4} lg={3}>
                    <MembershipCard ums={userMemberships} m={m}
                                    setRequested={async () => {
                                        setRequestedAny(true)
                                        await refreshUserMemberships()
                                        await refreshMemberships()
                                    }}/>
                </Grid>)}
        </Grid>
        {memberships.length === 0 && <TTypography sx={{mt: 3}}>No memberships found for organisation</TTypography>}
    </>
}

enum CardState {
    Owned,
    Unpaid,
    Available
}

function getCardState(m: Membership, ums: UserMembership[]) {
    if (!ums || !m)
        return CardState.Available
    const um = ums.find(e => e.membership.id === m.id)
    if (!um) return CardState.Available
    return um.paid ? CardState.Owned : CardState.Unpaid
}

function MembershipCard({m, setRequested, ums}: {
    ums: UserMembership[],
    m: Membership,
    setRequested: VoidFunction
}) {
    const {error} = useNotifications()
    const {ORG_URI, organisation} = useOrganisation()
    const {t} = useTranslation()
    const {forceAuthCheck} = useAuth()

    const fromString = new Date(m.start).getTime() < new Date().getTime()
        ? t("Present")
        : dateString(m.start)

    const timeString = `(${fromString} - ${dateString(m.expiry)})`

    const priceString = m.feeToJoin > 0
        ? `€ ${m.feeToJoin}`
        : t('Free')

    async function subscribe() {
        try {
            await postObject(`/Organisations/Memberships/${m.id}`, {})
            setRequested()
            forceAuthCheck()
        } catch (e: any) {
            error(e)
        }
    }

    let btnText: string
    switch (getCardState(m, ums)) {
        case CardState.Owned:
            btnText = "Owned"
            break
        case CardState.Available:
            btnText = "Request to join"
            break
        default:
            btnText = "Subscribe"
    }

    async function settlePayment() {
        const response = await postObjectWithResponse<EntityPaymentRequest, string>(`${ORG_URI}/Payments/Membership`, {
            successUri: `${window.location.origin}/${organisation.uriParam}/payment/success`,
            failedUri: `${window.location.origin}/${organisation.uriParam}/payment/error/Something has gone wrong`,
            entityId: ums.find(e => e.membership.id === m.id)?.id ?? '',
            eventId: EmptyGuid
        })
        return response.data
    }

    return <Card variant="solid" color="primary" invertedColors sx={{minWidth: 400}}>
        <CardContent orientation="horizontal">
            <CardContent>
                <Typography level="title-lg">{m.title} <small>{timeString}</small></Typography>
            </CardContent>
        </CardContent>
        <CardActions>
            <SpaceBetweenDiv sx={{width: '100%'}}>
                <div>
                    <Typography level={"title-lg"}>{priceString}</Typography>
                </div>
                {getCardState(m, ums) === CardState.Unpaid
                    ? <PaymentButton onClick={settlePayment}/>
                    : <Button onClick={subscribe} variant="solid" size="sm">
                        {t(btnText)}
                    </Button>}

            </SpaceBetweenDiv>
        </CardActions>
    </Card>
}
