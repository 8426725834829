import ClubEvent from "../types/ClubEvent";
import Organisation from "../types/Organisation";

export function useBanner(clubEvent: ClubEvent | null, organisation: Organisation | null) {
    const orgBanner = useOrganisationBanner(organisation)
    if (clubEvent === null) return undefined
    if (clubEvent.banner.length > 0) return clubEvent.banner
    return orgBanner
}

export function useOrganisationBanner(organisation: Organisation | null) {
    if (organisation === null) return undefined
    if (organisation.banner?.length > 0) return organisation.banner
    return undefined
}

export function getHslFromOrg(organisation: Organisation) {
    console.log(`org: ${organisation.name}, hslColor: ${organisation.settings.hslDefaultColor}`)
    if (organisation?.settings === null) return 'black'
    return `hsl(${organisation.settings.hslDefaultColor}, 100%, 45%)`
}
