import IconButton from "@mui/joy/IconButton";
import * as React from "react";
import {useAuth} from "../../../../hooks/contexts/Auth";
import LogoutIcon from '@mui/icons-material/Logout';
import {Tooltip} from "@mui/joy";
import useTranslation from "../../../../hooks/contexts/TranslationContext";

export default function LogoutBtn() {
    const {logout} = useAuth()
    const {t} = useTranslation()

    return <Tooltip title={t("Log out")}>
        <IconButton variant={"soft"} onClick={logout}><LogoutIcon/></IconButton>
    </Tooltip>
}
