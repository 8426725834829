import {ReactNode} from "react";
import {greyTheme} from "../../utils/theme";
import SetPublicPageInfo from "./SetPublicPageInfo";
import CssBaseline from "@mui/joy/CssBaseline";
import {CssVarsProvider, formLabelClasses, GlobalStyles} from "@mui/joy";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import {ProjectTitle} from "../../utils/globals";
import getRandomBackground from "../../utils/backgrounds";
import useTranslation from "../../hooks/contexts/TranslationContext";

export default function NotLoggedInLayout(props: { title: string, subTitle: string, children: ReactNode }) {
    const {t} = useTranslation()

    return <CssVarsProvider theme={greyTheme} disableTransitionOnChange>
        <SetPublicPageInfo/>
        <CssBaseline/>
        <GlobalStyles
            styles={{
                ':root': {
                    '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
                    '--Cover-width': '40vw', // must be `vw` only
                    '--Form-maxWidth': '700px',
                    '--Transition-duration': '0.4s', // set to `none` to disable transition
                },
            }}
        />
        <Box
            sx={() => ({
                width:
                    'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
                transition: 'width var(--Transition-duration)',
                transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                position: 'relative',
                zIndex: 1,
                display: 'flex',
                justifyContent: 'flex-end',
                backdropFilter: 'blur(4px)',
                bgcolor: 'background.surface',
            })}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100dvh',
                    width:
                        'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
                    maxWidth: '100%',
                    px: 2,
                }}
            >
                <Box
                    component="main"
                    sx={{
                        my: 'auto',
                        py: 2,
                        pb: 5,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        width: 400,
                        maxWidth: '100%',
                        mx: 'auto',
                        borderRadius: 'sm',
                        '& form': {
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                        },
                        [`& .${formLabelClasses.asterisk}`]: {
                            visibility: 'hidden',
                        },
                    }}
                >
                    <div>
                        <Typography component="h1" fontSize="xl2" fontWeight="lg">
                            {t(props.title)}
                        </Typography>
                        <Typography level="body-sm" sx={{my: 1, mb: 3}}>
                            {t(props.subTitle)}
                        </Typography>
                    </div>
                    {props.children}
                </Box>
                <Box component="footer" sx={{py: 3}}>
                    <Typography level="body-xs" textAlign="center">
                        © {ProjectTitle} {new Date().getFullYear()}
                    </Typography>
                </Box>
            </Box>
        </Box>
        <Box
            sx={() => ({
                height: '100%',
                position: 'fixed',
                right: 0,
                top: 0,
                bottom: 0,
                left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
                transition:
                    'background-image var(--Transition-duration), left var(--Transition-duration) !important',
                transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                backgroundColor: 'background.level1',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundImage:
                    `url(${getRandomBackground()})`,
            })}
        />
    </CssVarsProvider>
}
