import SaveIconMui from '@mui/icons-material/Save';
import DeleteIconMui from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchIconMui from '@mui/icons-material/Search';
import AddIcon from "@mui/icons-material/Add";
import {Edit} from "@mui/icons-material";

export const CreateIcon = AddIcon
export const SaveIcon = SaveIconMui
export const UpdateIcon = SaveIcon
export const ModifyIcon = Edit
export const DeleteIcon = DeleteIconMui
export const GetIcon = VisibilityIcon
export const ViewIcon = GetIcon
export const SearchIcon = SearchIconMui
