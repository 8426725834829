import {UserEventRegistration} from "../../../../../types/ClubEvent";
import {useState} from "react";
import useTranslation from "../../../../../hooks/contexts/TranslationContext";
import useNotifications from "../../../../../hooks/contexts/NotificationContext";
import {deleteObject, postFile, postObject} from "../../../../../functions/objectService";
import axios from "axios";
import {Card, CardContent, Tooltip} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import {dateTimeString} from "../../../../../utils/date";
import IconButton from "@mui/joy/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Button from "@mui/joy/Button";
import Modal from "../../../../layout/modal/Modal";
import Box from "@mui/joy/Box";
import FileDrop from "../../../../form/FileDrop";
import {Download} from "@mui/icons-material";
import {DeleteIcon} from "../../../../layout/CrudIcons";
import PaidIcon from "@mui/icons-material/Paid";
import ExtraOptionsSection from "./ExtraOptionsSection";

export default function AssignTicketCard({reg, refresh}: { reg: UserEventRegistration, refresh: VoidFunction }) {
    const [openMore, setOpenMore] = useState(false)
    const [openUpload, setOpenUpload] = useState(false)
    const {t} = useTranslation()
    const {success, error} = useNotifications()

    async function handleUpload(file: File) {
        try {
            await postFile(`Organisations/Events/Get/${reg.ticket.id}/Tickets/Attachment`, file)
            refresh()
            setOpenUpload(false)
            success(t("Successfully uploaded file"))
        } catch (e: any) {
            error(e)
        }
    }


    async function deleteAttachment() {
        try {
            await deleteObject(`Organisations/Events/Get/${reg.ticket.id}/Tickets/Attachment`)
            refresh()
            setOpenUpload(false)
            success(t("Successfully unlinked file"))
        } catch (e: any) {
            error(e)
        }
    }

    async function togglePaid() {
        await postObject(`/Organisations/Events/Tickets/${reg.ticket.id}`, {
            value: !reg.ticket.paid
        })
        refresh()
    }

    const attachmentUrl = `${axios.defaults.baseURL}/Organisations/Events/Get/${reg.ticket.id}/Tickets/Attachment`

    return <>
        <Card sx={{width: 320, m: 1}}>
            <div>
                <Typography level="title-lg">{reg.user.fullName}</Typography>
                <Typography
                    level="body-sm">{`${t("Created on")} ${dateTimeString(reg.ticket.createdOn)}`}</Typography>
                <Tooltip title={t("Options")}>
                    <IconButton
                        aria-label="remove ticket"
                        variant="plain"
                        color="neutral"
                        size="sm"
                        sx={{position: 'absolute', top: '0.875rem', right: '0.5rem'}}
                        onClick={() => setOpenMore(true)}
                    >
                        <MoreHorizIcon/>
                    </IconButton>
                </Tooltip>
            </div>
            <CardContent orientation="horizontal">
                <div>
                    <Typography level="body-xs">{t("Payment status")}:</Typography>
                    <Typography fontSize="lg" fontWeight="lg" color={reg.ticket.paid ? "success" : "danger"}>
                        {t(reg.ticket.paid ? "Paid" : "Pending")}
                    </Typography>
                </div>
                {reg.ticket.attachment === null && <Button
                    onClick={() => setOpenUpload(true)}
                    variant="solid"
                    size="md"
                    color="primary"
                    aria-label="Explore Bahamas Islands"
                    sx={{ml: 'auto', alignSelf: 'center', fontWeight: 600}}
                >
                    {t("Attach file")}
                </Button>}
            </CardContent>
        </Card>
        <Modal title={"Attach document to ticket"}
               open={openUpload}
               onClose={() => setOpenUpload(false)}>
            <Box sx={{my: 3, mx: 2}}>
                <FileDrop onDrop={acceptedFiles => handleUpload(acceptedFiles[0])} multiple={false}/>
            </Box>
        </Modal>
        <Modal title={`Ticket: ${reg.user.fullName}`}
               sx={{minWidth: 400}}
               open={openMore}
               onClose={() => setOpenMore(false)}>
            {reg.ticket.attachment === null && <ExtraOptionsSection
                title={"You can upload an attachment to this ticket, this file can be downloaded by the ticket holder. (Perhaps a physical ticket?)"}>
                <Button sx={{mx: 1}} color={"neutral"} onClick={() => {
                    setOpenMore(false)
                    setOpenUpload(true)
                }}><Download/> {t("Upload attachment")}</Button>
            </ExtraOptionsSection>}
            {reg.ticket.attachment !== null && <ExtraOptionsSection
                title={"You can remove the attachment, the file will still be saved in your cloud!"}>
                <Button
                    onClick={() => window.open(attachmentUrl)}
                    sx={{mx: 1}}
                    color={"success"}>
                    <Download/> {t("Download attachment")}
                </Button>
                <Button onClick={deleteAttachment} sx={{mx: 1}} color={"danger"}>
                    <DeleteIcon/> {t("Remove attachment")}
                </Button>
            </ExtraOptionsSection>}
            <ExtraOptionsSection
                title={"Be careful! People may have various ways of transferring the payment!"}>
                <Button
                    onClick={togglePaid}
                    sx={{mx: 1}}
                    color={"warning"}><PaidIcon/> {t(reg.ticket.paid ? "Set to pending payment" : "Set as paid")}
                </Button>
            </ExtraOptionsSection>
            <ExtraOptionsSection
                title={"This cannot be undone. If the event has not yet started, new tickets will be assigned to the next eligible person."}>
                <Button sx={{mx: 1}} color={"danger"}><DeleteIcon/> {t("Delete ticket")}</Button>
            </ExtraOptionsSection>
        </Modal>
    </>
}
