import {useEffect, useState} from "react";

const prefix = 'SupportersClub_BrowserSetting_'

export default function useBrowserSetting(name: string, defaultValue: boolean = false): {
    value: boolean,
    toggle: VoidFunction
} {
    const [value, setValue] = useState(defaultValue)

    const settingName = `${prefix}${name}`

    useEffect(() => {
        const storageValue = localStorage.getItem(settingName)
        setValue(storageValue !== null)
    }, [settingName])

    function toggle() {
        if (value) {
            localStorage.removeItem(settingName)
            setValue(false)
        } else {
            localStorage.setItem(settingName, "1")
            setValue(true)
        }
    }

    return {value, toggle}
}