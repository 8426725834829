import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import {useParams} from "react-router-dom";
import useGetSingle from "../../../hooks/useHttp";
import ClubEvent from "../../../types/ClubEvent";
import Error500 from "../../errors/Error500";
import {Container} from "@mui/joy";
import useOrganisation from "../../../hooks/contexts/OrganisationContext";
import {ReactNode, useEffect, useState} from "react";
import {FIRST_COME_FIRST_SERVED_EVENT_EXPLAINED, PRIORITIZE_LOYALTY_EVENT_EXPLAINED} from "../../../utils/eventInfo";
import Button from "@mui/joy/Button";
import LoadingPage from "../../LoadingPage";
import {Refresh} from "@mui/icons-material";
import {postObject} from "../../../functions/objectService";
import {dateTimeString} from "../../../utils/date";
import useNotifications from "../../../hooks/contexts/NotificationContext";
import useTranslation from "../../../hooks/contexts/TranslationContext";
import {SpaceBetweenDiv} from "../../../components/layout/FlexDiv";
import {ModifyIcon} from "../../../components/layout/CrudIcons";
import TTypography from "../../../components/layout/joy/TTypography";
import PrintHtml from "../../../components/layout/PrintHtml";
import {anyText} from "../../../components/form/RichEditor";

export default function Details() {
    const {clubEventId} = useParams<{ clubEventId: string }>()
    const {data: event, firstLoading} = useGetSingle<ClubEvent | null>(`/organisations/events/get/${clubEventId}`, null)
    const {organisation, isOrgAdmin, orgNavigate, userIsSubscribed} = useOrganisation()
    const {t} = useTranslation()

    useEffect(() => {
        if (!userIsSubscribed)
            orgNavigate("/Memberships")
    }, [userIsSubscribed, orgNavigate, isOrgAdmin]);

    if (firstLoading) return <LoadingPage/>
    if (event === null) return <Error500/>
    const canRegister = new Date(event.startRegistrations).getTime() < new Date().getTime()
        && new Date(event.endRegistrations).getTime() > new Date().getTime()


    return <Container>
            <SpaceBetweenDiv>
                <Typography level="h1">
                    {event.expandedTitle}
                </Typography>
                {isOrgAdmin && <div>
                    <Button onClick={() => orgNavigate(`/events/${clubEventId}/manage`)}>
                        <ModifyIcon sx={{mr: 1}}/>
                        {t("Manage event")}
                    </Button>
                </div>}
            </SpaceBetweenDiv>
            {anyText(event.description)
                && <DetailSection>
                    <PrintHtml html={event.description}/>
                </DetailSection>}

            <DetailSection title={"Meetup address"}>{organisation.settings.defaultEventMeetupAddress}</DetailSection>
            <DetailSection title={"Ticket info"}>
                <ul>
                    {event.fee > 0 && <li>
                        <TTypography>After your registration has been approved,
                            you must pay your ticket within 24 hours, or your spot will go to the next person in
                            line.</TTypography>
                    </li>}
                    {event.fee > 0 &&
                        <li>{t("A ticket for this event costs €[VAR_PRICE].").replace("[VAR_PRICE]", `${event.fee}`)}</li>}
                    {event.limitOfParticipants > 0 &&
                        <li>{t("This event has a limit of [VAR_AMOUNT] participants.").replace("[VAR_AMOUNT]", `${event.limitOfParticipants}`)} {event.useFirstComeFirstServedPrinciple
                                ? t(FIRST_COME_FIRST_SERVED_EVENT_EXPLAINED)
                                : t(PRIORITIZE_LOYALTY_EVENT_EXPLAINED)}</li>}
                    {event.limitOfRegistrations > 0 &&
                        <li>{t("This event has a limit of [VAR_AMOUNT] registrations.").replace("[VAR_AMOUNT]", `${event.limitOfRegistrations}`)}</li>}
                    <li>{t("Registrations of this event start at [START_DATE] and will be open until [END_DATE].")
                        .replace("[START_DATE]", `${dateTimeString(event.startRegistrations)}`)
                        .replace("[END_DATE]", `${dateTimeString(event.endRegistrations)}`)}</li>
                </ul>
            </DetailSection>
            {canRegister && <DetailSection title={"Register for this event"}>
                <Box sx={{mt: 1}}>
                    {clubEventId === undefined
                        ? <Button disabled><Refresh/> Loading</Button>
                        : <RegistrationButtons event={event}/>
                    }
                </Box>
            </DetailSection>}
    </Container>
}

function RegistrationButtons(props: { event: ClubEvent }) {
    const {
        data: registered,
        loading, refresh
    } = useGetSingle<boolean>(`/Organisations/Events/Registered/${props.event.id}`, false)
    const [actionLoading, setActionLoading] = useState(false)
    const {error} = useNotifications()

    if (loading || actionLoading) return <Button disabled><Refresh/> Loading registrations</Button>

    async function register() {
        try {
            setActionLoading(true)
            await postObject(`/Organisations/Events/Register/${props.event.id}`, {})
            await refresh()
        } catch (e) {
            error(`${e}`)
        } finally {
            setActionLoading(false)
        }
    }

    async function cancel() {
        try {
            setActionLoading(true)
            await postObject(`/Organisations/Events/CancelRegistration/${props.event.id}`, {})
            await refresh()
        } catch (e) {
            error(`${e}`)
        } finally {
            setActionLoading(false)
        }
    }

    return <>
        <Button onClick={register} color={"primary"} sx={{mr: 1}} disabled={registered}>
            Register for ticket
        </Button>
        {registered &&
            <Button disabled={props.event.startEvent < new Date()} onClick={cancel} color={"danger"} sx={{mr: 1}}>
                Cancel registration
            </Button>}
    </>
}

function DetailSection(props: { title?: string, children: ReactNode }) {
    const {t} = useTranslation()

    return <Box sx={{mb: 2}}>
        {props.title && <Typography level={"title-md"}>{t(props.title)}</Typography>} {props.children}
    </Box>
}
