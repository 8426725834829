import {useForm} from "react-hook-form";
import TabSettingLayout from "./TabSettingLayout";
import InputControl from "../../form/InputControl";
import useGetSingle from "../../../hooks/useHttp";
import useOrganisation from "../../../hooks/contexts/OrganisationContext";
import LoadingPage from "../../../pages/LoadingPage";
import Error500 from "../../../pages/errors/Error500";
import IosSwitchControl from "../../form/IosSwitchControl";
import {SwitchAlignLeft} from "./EventSettings";
import {useEffect, useState} from "react";
import useNotifications from "../../../hooks/contexts/NotificationContext";
import {putObject} from "../../../functions/objectService";
import {CenterDiv} from "../../layout/FlexDiv";
import Button from "@mui/joy/Button";
import {SaveIcon} from "../../layout/CrudIcons";
import useTranslation from "../../../hooks/contexts/TranslationContext";
import useShowPassword from "../../../hooks/useShowPassword";

export default function SmtpConfig() {
    const {ORG_URI} = useOrganisation()
    const {data, firstLoading, error, refresh} = useGetSingle<{
        host: string,
        username: string,
        password: string,
        port: number,
        useSsl: boolean
    } | null>(`${ORG_URI}/Settings/Smtp`, null)
    const {register, getValues} = useForm()
    const [useSsl, setUseSsl] = useState(data?.useSsl ?? true)
    const {error: errorNot, success} = useNotifications()
    const {t} = useTranslation()
    const {type, decorator} = useShowPassword()

    useEffect(() => {
        setUseSsl(data?.useSsl ?? true)
    }, [data]);

    if (firstLoading)
        return <LoadingPage/>
    if (error)
        return <Error500/>

    async function onSubmit() {
        try {
            await putObject(`${ORG_URI}/Settings/Smtp`, {
                host: getValues("host"),
                username: getValues("username"),
                password: getValues("password"),
                port: getValues("port"),
                useSsl,
            })
            success(t("Successfully saved mail settings"))
            await refresh()
        } catch (e: any) {
            errorNot(e)
        }
    }

    return <>
        <TabSettingLayout question={"Host"}>
            <InputControl register={register("host", {value: data?.host})}/>
        </TabSettingLayout>
        <TabSettingLayout question={"Username"}>
            <InputControl register={register("username", {value: data?.username})}/>
        </TabSettingLayout>
        <TabSettingLayout question={"Password"}>
            <InputControl
                inputProps={{type, endDecorator: decorator}}
                register={register("password", {value: data?.password})}/>
        </TabSettingLayout>
        <TabSettingLayout question={"Port"}>
            <InputControl register={register("port", {value: data?.port})}/>
        </TabSettingLayout>
        <TabSettingLayout
            question={"Use SSL"}>
            <SwitchAlignLeft>
                <IosSwitchControl
                    checked={useSsl}
                    setChecked={() => setUseSsl(!useSsl)}/>
            </SwitchAlignLeft>
        </TabSettingLayout>
        <CenterDiv>
            <Button color={"success"} onClick={onSubmit}><SaveIcon/> Save settings</Button>
        </CenterDiv>
    </>
}
