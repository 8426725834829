import Button from "@mui/joy/Button";
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import useTranslation from "../../hooks/contexts/TranslationContext";
import useNotifications from "../../hooks/contexts/NotificationContext";
import {useState} from "react";
import {SxProps} from "@mui/joy/styles/types";
import LoopIcon from '@mui/icons-material/Loop';
import "./../../media/css/rotating.css"

export default function PaymentButton({onClick, sx = undefined}: { onClick: () => Promise<string>, sx?: SxProps }) {
    const {t} = useTranslation()
    const {error} = useNotifications()
    const [loading, setLoading] = useState(false)

    async function clickHandler() {
        try {
            setLoading(true)
            window.location.href = await onClick()
        } catch (e: any) {
            error(e)
        } finally {
            setLoading(false)
        }
    }

    return <Button sx={sx} disabled={loading} onClick={clickHandler} color={"primary"} variant={"soft"}>
        {loading
            ? <LoopIcon className={"rotating"} sx={{mr: 2}}/>
            : <ShoppingCartCheckoutIcon sx={{mr: 2}}/>}
        {t("Pay now")}
    </Button>
}
