import NotLoggedInLayout from "../../components/layout/NotLoggedInLayout";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Box from "@mui/joy/Box";
import Link from "@mui/joy/Link";
import Button from "@mui/joy/Button";
import {useNavigate} from "react-router-dom";
import {postObject} from "../../functions/objectService";
import useTranslation from "../../hooks/contexts/TranslationContext";
import {useState} from "react";
import LoadingButton from "../../components/layout/form/LoadingButton";
import useNotifications from "../../hooks/contexts/NotificationContext";

export default function ForgotPassword() {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const [isLoading, setLoading] = useState(false)
    const {error} = useNotifications()

    return <NotLoggedInLayout title={"Forgot your password?"} subTitle={"Insert your email to reset your password!"}>
        <form
            onSubmit={async (event: any) => {
                try {
                    setLoading(true)
                    event.preventDefault();
                    const formElements = event.currentTarget.elements;
                    await postObject("/auth/password/reset", {
                        email: formElements.email.value
                    })
                    navigate("/login")
                } catch (e: any) {
                    error(e)
                } finally {
                    setLoading(false)
                }
            }}
        >
            <FormControl required>
                <FormLabel>Email</FormLabel>
                <Input type="email" name="email"/>
            </FormControl>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Link fontSize="sm" onClick={() => navigate("/login")} fontWeight="lg" color={"neutral"}>
                    {t("Login")}
                </Link>
                <Link fontSize="sm" onClick={() => navigate("/register")} fontWeight="lg" color={"neutral"}>
                    {t("Register")}
                </Link>
            </Box>
            {isLoading
                ? <LoadingButton/>
                : <Button type="submit" fullWidth color={"neutral"}>
                    {t("Send recovery email")}
                </Button>}

        </form>
    </NotLoggedInLayout>
}
