import Sheet from "@mui/joy/Sheet/Sheet";
import Table from "@mui/joy/Table";
import useTranslation from "../../../../hooks/contexts/TranslationContext";
import {useGetCollection} from "../../../../hooks/useHttp";
import {UserEventRegistrationWithPoints} from "../../../../types/ClubEvent";
import useClubEvent from "../../../../hooks/contexts/ClubEventContext";
import Error500 from "../../../../pages/errors/Error500";
import LoadingPage from "../../../../pages/LoadingPage";
import {FlexDiv, SpaceBetweenDiv} from "../../../layout/FlexDiv";
import CircleIcon from '@mui/icons-material/Circle';
import Typography from "@mui/joy/Typography";
import useTranslatedEventInfo from "../../../../hooks/useTranslatedEventInfo";
import {useAuth} from "../../../../hooks/contexts/Auth";
import Button from "@mui/joy/Button";
import useNotifications from "../../../../hooks/contexts/NotificationContext";
import {postObject} from "../../../../functions/objectService";
import useOrganisation from "../../../../hooks/contexts/OrganisationContext";
import CheckIcon from '@mui/icons-material/Check';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import {Tooltip} from "@mui/joy";

export default function RegistrationsTab() {
    const {FIRST_COME_FIRST_SERVED_EVENT_EXPLAINED, PRIORITIZE_LOYALTY_EVENT_EXPLAINED} = useTranslatedEventInfo()
    const {t} = useTranslation()
    const {clubEvent} = useClubEvent()
    const {user} = useAuth()
    const {success, error: errorNot} = useNotifications()
    const {organisation} = useOrganisation()
    const {
        data: registrations,
        firstLoading,
        error,
        refresh
    } = useGetCollection<UserEventRegistrationWithPoints>(`Organisations/Events/${clubEvent?.id}/Registrations`)

    if (firstLoading) return <LoadingPage/>
    if (error) return <Error500/>

    const anyAdminTicket = registrations
        .filter(a => a.registration.user.id === user?.id)
        .length > 0

    async function createAdminTicket() {
        try {
            await postObject(`/Organisations/Events/${organisation.id}/Tickets/CreateForAdmin`, {eventId: clubEvent?.id})
            success(t("Successfully created ticket for admin"))
            await refresh()
        } catch (e: any) {
            errorNot(e)
        }
    }

    async function giveTicket(userId:string) {
        await postObject(`/Organisations/Events/${organisation.id}/Tickets/Give`, {userId, eventId: clubEvent?.id})
        await refresh()
    }

    return <>
        <SpaceBetweenDiv>
            {!anyAdminTicket ?
                <Button onClick={createAdminTicket}>{t('Create admin ticket')}</Button>
                : <div></div>}
            <Legend/>
        </SpaceBetweenDiv>
        <Sheet sx={{height: "100%", overflow: 'auto'}}>
            <Table
                aria-label="table with sticky header"
                stickyHeader
                hoverRow
                sx={{maxHeight: "90%"}}
            >
                <thead>
                <tr>
                    <th style={{textAlign: "center"}}>Qualified</th>
                    <th>{t("Name")}</th>
                    <th>{t("Points")}</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {registrations
                    .sort((a, b) => b.points.points - a.points.points)
                    .map((row, index) => (
                        <tr key={row.registration.user.id}>
                            <td style={{textAlign: "center"}}>
                                {index < (clubEvent?.limitOfParticipants ?? Number.MAX_VALUE)
                                    ? <QualifiedIcon/>
                                    : <UnQualifiedIcon/>}
                            </td>
                            <td>{row.registration.user.fullName}</td>
                            <td>{row.points.points}</td>
                            <td>{row.registration.ticket === null
                                ? <Button onClick={() => giveTicket(row.registration.user.id)}>{t('Give ticket')}</Button>
                                : (row.registration.ticket.paid
                                    ? <Tooltip title={t("Ticket payment has succeeded!")}>
                                        <CheckIcon />
                                </Tooltip>
                                    : <Tooltip title={t("Ticket assigned to person, waiting for payment")}>
                                        <WatchLaterIcon />
                                    </Tooltip>)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Sheet>
        <Typography level={"body-xs"} sx={{textAlign: "center", mt: 2}}>
            {clubEvent?.useFirstComeFirstServedPrinciple
                ? FIRST_COME_FIRST_SERVED_EVENT_EXPLAINED
                : PRIORITIZE_LOYALTY_EVENT_EXPLAINED}
        </Typography>
    </>
}

function Legend() {
    const {t} = useTranslation()

    return <FlexDiv>
        <FlexDiv sx={{mx: 1}}><QualifiedIcon/> {t("Qualified for ticket")} </FlexDiv>
        <FlexDiv sx={{mx: 1}}><UnQualifiedIcon/> {t("No ticket")} </FlexDiv>
    </FlexDiv>
}

function QualifiedIconDesign(props: { color: "success" | "danger" }) {
    return <Typography color={props.color} sx={{mx: 1}}>
        <CircleIcon/>
    </Typography>
}

function QualifiedIcon() {
    return <QualifiedIconDesign color={"success"}/>
}

function UnQualifiedIcon() {
    return <QualifiedIconDesign color={"danger"}/>
}
