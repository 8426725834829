import {ClubEventType} from "../../../../types/ClubEvent";
import {useForm} from "react-hook-form";
import FormCardHeader from "../../../../components/layout/form/FormCardHeader";
import Input from "@mui/joy/Input";
import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import {Grid} from "@mui/joy";
import FormActions from "../../../../components/layout/form/FormActions";
import Button from "@mui/joy/Button";

export default function EventTypeForm({eventType, onSubmit}: {
    onSubmit: (element: ClubEventType) => void,
    eventType: ClubEventType
}) {
    const {register, handleSubmit} = useForm<ClubEventType>()

    return <>
        <FormCardHeader title={""}
                        subtitle={"Every event has a type to allow categorization per organisation, and define points per category (if using the loyalty points)."}/>
        <FormControl sx={{m: 1}}>
            <FormLabel>Name</FormLabel>
            <Input {...register("name", {value: eventType.name})}/>
        </FormControl>
        <Grid container>
            <Grid md={6}>
                <FormControl sx={{m: 1}}>
                    <FormLabel>Point on register</FormLabel>
                    <Input {...register("pointsOnRegister", {value: eventType.pointsOnRegister, valueAsNumber: true})}/>
                </FormControl>
            </Grid>
            <Grid md={6}>
                <FormControl sx={{m: 1}}>
                    <FormLabel>Point on participate</FormLabel>
                    <Input {...register("pointsOnParticipate", {
                        value: eventType.pointsOnParticipate,
                        valueAsNumber: true
                    })}/>
                </FormControl>
            </Grid>
        </Grid>
        <FormActions>
            <Button onClick={handleSubmit(onSubmit)}>Save</Button>
        </FormActions>
    </>
}
