import useTranslation from "../../hooks/contexts/TranslationContext";
import {useNavigate} from "react-router-dom";
import NotLoggedInLayout from "../../components/layout/NotLoggedInLayout";
import Button from "@mui/joy/Button";
import EastIcon from "@mui/icons-material/East";

export default function AccountVerified() {
    const {t} = useTranslation()
    const navigate = useNavigate()

    return (
        <NotLoggedInLayout
            title={"Account verified"}
            subTitle={"Your can now log into your account!"}
        >
            <Button onClick={() => navigate("/login")} color={"success"}>
                <EastIcon sx={{mr: 1}}/> {t("Login")}
            </Button>
        </NotLoggedInLayout>
    );
}