import {Tab, TabList, TabPanel, Tabs} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import EventSettings from "../../components/organisation/settings/EventSettings";
import OrganisationCloud from "../../components/organisation/settings/OrganisationCloud";
import SmtpConfig from "../../components/organisation/settings/SmtpConfig";
import useTranslation from "../../hooks/contexts/TranslationContext";
import MembershipsSettings from "../../components/organisation/settings/MembershipsSettings";
import PaymentInfoSettings from "../../components/organisation/settings/PaymentInfoSettings";
import useScreen from "../../hooks/useScreen";
import {PublicDisplaySettings} from "../../components/organisation/settings/PublicDisplaySettings";

enum Page {
    Organisation,
    Event,
    Memberships,
    Mail,
    Payments,
    Storage
}

export default function OrgSettings() {
    const {t} = useTranslation()
    const {PhoneOrTablet} = useScreen()

    const orientation = PhoneOrTablet ? "vertical" : "horizontal"

    return <>
        <Typography level={"h2"} sx={{mb: 2}}>Settings</Typography>
        <Tabs orientation={orientation} defaultValue={0} variant={"soft"}>
            <TabList tabFlex="auto">
                <Tab value={Page.Organisation}>{t("Organisation")}</Tab>
                <Tab value={Page.Event}>{t("Event")}</Tab>
                <Tab value={Page.Memberships}>{t("Memberships")}</Tab>
                {!PhoneOrTablet && <Tab value={Page.Payments}>{t("Payments")}</Tab>}
                {!PhoneOrTablet && <Tab disabled value={Page.Mail}>{t("Mail (soon)")}</Tab>}
                {!PhoneOrTablet && <Tab value={Page.Storage}>{t("Storage")}</Tab>}
            </TabList>
            <TabPanel value={Page.Organisation}>
                <PublicDisplaySettings/>
            </TabPanel>
            <TabPanel value={Page.Event}>
                <EventSettings/>
            </TabPanel>
            <TabPanel value={Page.Mail}>
                <SmtpConfig/>
            </TabPanel>
            <TabPanel value={Page.Storage}>
                <OrganisationCloud/>
            </TabPanel>
            <TabPanel value={Page.Memberships}>
                <MembershipsSettings/>
            </TabPanel>
            <TabPanel value={Page.Payments}>
                <PaymentInfoSettings/>
            </TabPanel>
        </Tabs>
    </>
}
