import useClubEvent from "../../../../../hooks/contexts/ClubEventContext";
import useNotifications from "../../../../../hooks/contexts/NotificationContext";
import useTranslation from "../../../../../hooks/contexts/TranslationContext";
import {AlignRightDiv} from "../../../../layout/FlexDiv";
import Input from "@mui/joy/Input";
import ClubEvent from "../../../../../types/ClubEvent";
import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";

export default function ModifyAmountOfTickets() {
    const {clubEvent, updateEvent, saveChanges} = useClubEvent()
    const {warning} = useNotifications()
    const {t} = useTranslation()

    return <AlignRightDiv>
        <FormControl>
            <FormLabel>{t("Maximum tickets")}</FormLabel>
            <Input
                type={"number"}
                value={clubEvent?.limitOfParticipants}
                onChange={(e) => {
                    if (clubEvent === undefined || !e.target.value || parseInt(e.target.value) < 0) {
                        warning(t("Invalid value for field. Insert 0 for infinite amount of tickets."))
                        return;
                    }
                    updateEvent({...clubEvent as ClubEvent, limitOfParticipants: parseInt(`${e.target.value}`)})
                    saveChanges()
                }}/>
        </FormControl>
    </AlignRightDiv>
}