import NotLoggedInLayout from "../../components/layout/NotLoggedInLayout";
import {useNavigate} from "react-router-dom";
import Button from "@mui/joy/Button";
import useTranslation from "../../hooks/contexts/TranslationContext";
import EastIcon from '@mui/icons-material/East';

export default function VerifyAccount() {
    const {t} = useTranslation()
    const navigate = useNavigate()

    return (
        <NotLoggedInLayout
            title={"Verify your account"}
            subTitle={"Please check your mail inbox for additional steps."}
        >
            <Button onClick={() => navigate("/login")} color={"neutral"}>
                <EastIcon sx={{mr: 1}}/> {t("Login")}
            </Button>
        </NotLoggedInLayout>
    );
}
