import {UserEventRegistration} from "../../../../types/ClubEvent";
import useClubEvent from "../../../../hooks/contexts/ClubEventContext";
import {useGetCollection} from "../../../../hooks/useHttp";
import {FlexDiv} from "../../../layout/FlexDiv";
import LoadingPage from "../../../../pages/LoadingPage";
import Error500 from "../../../../pages/errors/Error500";
import ModifyAmountOfTickets from "./AssignTicketTabComponents/ModifyAmountOfTickets";
import AssignTicketCard from "./AssignTicketTabComponents/AssignTicketCard";

export default function AssignTicketsTab() {
    const {clubEvent} = useClubEvent()
    const {
        data: registrations,
        firstLoading,
        error,
        refresh
    } = useGetCollection<UserEventRegistration>(`Organisations/Events/Get/${clubEvent?.id}/Tickets`)

    if (firstLoading) return <LoadingPage/>
    if (error) return <Error500/>

    return <>
        <ModifyAmountOfTickets/>
        <FlexDiv>
            {registrations
                .sort((a, b) => {
                    if (a.ticket !== null && b.ticket !== null) return 0
                    return a.ticket !== null ? 1 : -1
                })
                .map(e => <AssignTicketCard key={e.user.id} refresh={refresh} reg={e}/>)}
        </FlexDiv>
    </>
}
