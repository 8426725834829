import Membership from "./Membership";
import OrganisationImage from "./OrganisationImage";
import {ClubEventPhase} from "./ClubEvent";
import {getPaletteFromNumber, greyTheme} from "../utils/theme";
import {extendTheme} from "@mui/joy/styles";
import {Entity} from "./entity";
import {ApplicationUser} from "./ApplicationUser";

export default interface Organisation extends Entity {
    name: string
    shortName: string
    status: OrganisationPhase
    uriParam: string
    logo: string
    banner: string
    settings: OrganisationSettings
    images: OrganisationImage[]
    memberships: Membership[]
    owner: ApplicationUser | null
    getSafeTimezoneId: string
}

export interface OrganisationSettings {
    aboutPage: string
    defaultEventMeetupAddress: string
    defaultUseFirstComeFirstServedPrinciple: boolean
    mustConfirmNewMembers: boolean
    verifyWhoParticipated: boolean
    membersCanSeeArchivedEvents: boolean
    membersCanSeeEventFromPhase: ClubEventPhase
    hslDefaultColor: number
    archiveEventsAfterDays: number
    smtpConfig: {
        host: null | string,
        port: number,
        smtpNotConfigured: boolean,
        username: string | null,
        useSsl: boolean
    }
}

export function getThemeForOrganisation(settings: OrganisationSettings | null | undefined) {
    if (settings === null || settings === undefined) return greyTheme
    return extendTheme({
        colorSchemes: {
            light: {
                palette: {
                    primary: {
                        ...getPaletteFromNumber(settings.hslDefaultColor)
                    },
                },
            },
            dark: {
                palette: {
                    primary: {
                        ...getPaletteFromNumber(settings.hslDefaultColor)
                    },
                },
            },
        },
    })
}

export enum OrganisationPhase {
    Creation = 0,
    Active = 1,
    Archived = 2
}

export function emptyOrganisation(): Organisation {
    return {
        id: '00000000-0000-0000-0000-000000000000',
        name: '',
        banner: '',
        uriParam: '',
        images: [],
        logo: '',
        memberships: [],
        getSafeTimezoneId: '',
        settings: {
            mustConfirmNewMembers: false,
            aboutPage: '',
            defaultEventMeetupAddress: '',
            defaultUseFirstComeFirstServedPrinciple: false,
            membersCanSeeArchivedEvents: false,
            membersCanSeeEventFromPhase: ClubEventPhase.Draft,
            verifyWhoParticipated: false,
            hslDefaultColor: 0,
            archiveEventsAfterDays: 10,
            smtpConfig: {
                host: null,
                port: 587,
                smtpNotConfigured: false,
                username: null,
                useSsl: true
            }
        },
        shortName: '',
        status: OrganisationPhase.Creation,
        createdOn: new Date(),
        modifiedOn: new Date(),
        owner: null
    }
}

