import Organisation from "../../types/Organisation";
import {Card, CardContent, CardCover} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import {useNavigate} from "react-router-dom";
import {getHslFromOrg, useOrganisationBanner} from "../../hooks/useBanner";

export default function OrganisationBannerCard({org}: { org: Organisation }) {
    const navigate = useNavigate()
    const banner = useOrganisationBanner(org)

    const defaultSx = {height: 250, boxShadow: 'md', cursor: 'pointer', userSelect: 'none'}

    return <Card component="li"
                 sx={defaultSx}
                 onClick={() => navigate(`/${org.uriParam}`)}>
        <CardCover sx={!banner ? {background: getHslFromOrg(org)} : {}}>
            {banner && <img
                src={banner}
                loading="lazy"
                alt={`banner-${org.id}`}
            />}
        </CardCover>
        <CardCover
            sx={{
                background:
                    'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
            }}
        />
        <CardContent sx={{justifyContent: 'flex-end'}}>
            <Typography level="h2" textColor="#fff">
                {org.name}
            </Typography>
            <Typography level="title-sm" textColor="#fff">
                {org.settings.defaultEventMeetupAddress}
            </Typography>
        </CardContent>
    </Card>
}
