import {extendTheme} from '@mui/joy/styles';

extendTheme({
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    50: '#d9c0c0',
                    100: '#cfa5a5',
                    200: '#ca6a6a',
                    300: '#d04848',
                    400: '#dd2121',
                    500: '#de0909',
                    600: '#b51b1b',
                    700: '#952626',
                    800: '#682f2f',
                    900: '#4c2f2f',
                },
            },
        },
    },
});

export function getPaletteFromNumber(value: number) {
    return {
        50: `hsl(${value}, 100%, 95%)`,
        100: `hsl(${value}, 100%, 90%)`,
        200: `hsl(${value}, 100%, 80%)`,
        300: `hsl(${value}, 100%, 70%)`,
        400: `hsl(${value}, 100%, 60%)`,
        500: `hsl(${value}, 100%, 50%)`,
        600: `hsl(${value}, 100%, 40%)`,
        700: `hsl(${value}, 100%, 30%)`,
        800: `hsl(${value}, 100%, 20%)`,
        900: `hsl(${value}, 100%, 10%)`,
    }
}

export const greyTheme = extendTheme({
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    50: '#C8C8C8',
                    100: '#A8A8A8',
                    200: '#A8A8A8',
                    300: '#909090',
                    400: '#808080',
                    500: '#707070',
                    600: '#606060',
                    700: '#484848',
                    800: '#696969',
                    900: '#202020',
                },
            },
        },
    },
});

export const getSliderTheme = (color: number) => {
    return extendTheme({
        components: {
            JoySlider: {
                styleOverrides: {
                    track: {
                        background: `hsl(${color}, 100%, 50%)`,
                        height: '20px'
                    },
                    thumb: {
                        background: `hsl(${color}, 100%, 50%)`,
                        height: '30px',
                        width: '30px',
                        borderRadius: '50%'
                    },
                    rail: {
                        height: '20px'
                    }
                }
            }
        }
    });
}


// Then, pass it to `<CssVarsProvider theme={theme}>`.

