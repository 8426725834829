import {Grid, Tooltip} from "@mui/joy";
import {CenterDiv} from "../../../layout/FlexDiv";
import Button from "@mui/joy/Button";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import React, {useEffect} from "react";
import useClubEvent from "../../../../hooks/contexts/ClubEventContext";
import {dateTimeString} from "../../../../utils/date";
import {ClubEventPhase, EventModifyPhase, getLimitString} from "../../../../types/ClubEvent";
import useTranslation from "../../../../hooks/contexts/TranslationContext";
import TTypography from "../../../layout/joy/TTypography";
import {DeleteIcon} from "../../../layout/CrudIcons";

export default function LaunchEventTab() {
    const {clubEvent, updateEvent, saveChanges, setModifyPhase} = useClubEvent()
    const {t} = useTranslation()

    useEffect(() => {
        if (clubEvent?.status === ClubEventPhase.SetupCompleted)
            setModifyPhase(EventModifyPhase.ManageRegistrations)
    }, [clubEvent?.status, setModifyPhase]);

    if (clubEvent == null) return <></>

    function launch() {
        if (clubEvent === null) return;
        clubEvent.status = ClubEventPhase.SetupCompleted
        updateEvent(clubEvent)
        saveChanges()
        setModifyPhase(EventModifyPhase.ManageRegistrations)
    }

    function cancel() {
        if (clubEvent === null) return;
        clubEvent.status = ClubEventPhase.Cancelled
        updateEvent(clubEvent)
        saveChanges()
    }

    return <>
        <Grid container>
            <Grid md={4}>
                <TTypography level={"title-md"}>
                    Details for registrations
                </TTypography>
                <ul>
                    <li>
                        <><b>{t('Starts')}:</b> {dateTimeString(clubEvent?.startRegistrations)}</>
                    </li>
                    <li>
                        <><b>{t('Ends')}:</b> {dateTimeString(clubEvent?.endRegistrations)}</>
                    </li>
                    <li>
                        <><b>{t('Limit')}:</b> {getLimitString(clubEvent?.limitOfRegistrations)}</>
                    </li>
                </ul>
            </Grid>
            <Grid md={4}>
                <TTypography level={"title-md"}>
                    Details for tickets
                </TTypography>
                <ul>
                    <li>
                        <><b>{t('Price')}:</b> {clubEvent?.fee > 0 ? `€${clubEvent.fee}` : t('Free')}</>
                    </li>
                    <li>
                        <><b>{t('Limit')}:</b> {getLimitString(clubEvent.limitOfParticipants)}</>
                    </li>
                    <li>
                        <><b>{t('Ticket assignment')}:</b> {clubEvent.useFirstComeFirstServedPrinciple
                            ? t("First come, first served")
                            : t("Loyalty system")}</>
                    </li>
                    <li>
                        <><b>{t('Assignment date')}: </b>
                            <Tooltip
                                title={t("Tickets will be assigned when the registrations end. " +
                                    "Once the tickets are assigned you will be able to attach a file to it (such as a physical ticket).")}>
                                <>{dateTimeString(clubEvent?.endRegistrations)}</>
                            </Tooltip></>
                    </li>
                </ul>
            </Grid>
            <Grid md={4}>
                <TTypography level={"title-md"}>
                    Details for event
                </TTypography>
                <ul>
                    <li>
                        <><b>{t('Starts')}:</b> {dateTimeString(clubEvent.startEvent)}</>
                    </li>
                    <li>
                        <><b>{t('Ends')}:</b> {dateTimeString(clubEvent.endEvent)}</>
                    </li>
                    <li>
                        <><b>{t('Meetup address')}:</b> {clubEvent.eventMeetupAddress}</>
                    </li>
                </ul>
            </Grid>
        </Grid>
        <CenterDiv>
            <Tooltip title={t("Clicking this button will cancel the event.")}>
                <Button variant={"soft"} color={"danger"} size={"lg"} onClick={cancel} sx={{mx: 2}}
                        disabled={clubEvent.status !== ClubEventPhase.Draft}>
                    <DeleteIcon sx={{mr: 1}}/>
                    {t('Cancel Event')}
                </Button>
            </Tooltip>
            <Tooltip title={t("Clicking this button will make the event visible to your members.")}>
                <Button variant={"soft"} color={"success"} size={"lg"} onClick={launch} sx={{mx: 2}}
                        disabled={clubEvent.status !== ClubEventPhase.Draft}>
                    <RocketLaunchIcon sx={{mr: 1}}/>
                    {t('Launch Event')}
                </Button>
            </Tooltip>
        </CenterDiv>
    </>
}
