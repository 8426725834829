// noinspection SpellCheckingInspection

import {CaseInsensitiveMap} from "../types/CaseInsensitiveMap";

export const TranslationsNL = new CaseInsensitiveMap<string, string>();

TranslationsNL.set(
    'Registrations of this event start at [START_DATE] and will be open until [END_DATE].',
    'Registraties voor dit evenement beginnen op [START_DATE] and zullen lopen tot [END_DATE].'
)
TranslationsNL.set(
    'Storage',
    'Opslag'
)
TranslationsNL.set(
    'Full name',
    'Volledige naam'
)
TranslationsNL.set(
    'Modify',
    'Bewerken'
)
TranslationsNL.set(
    'Meetup address',
    'Adres van vertrekpunt'
)
TranslationsNL.set(
    'It looks like you have no types yet, you must create one before you can create an event!',
    'Je hebt nog geen evenement soorten toegevoegd, dit moet gebeuren voordat je een evenement kan aanmaken!'
)
TranslationsNL.set(
    'Members of organisation',
    'Leden van organisatie'
)
TranslationsNL.set(
    'Options',
    'Opties'
)
TranslationsNL.set(
    'Show archived tickets',
    'Toon archief'
)
TranslationsNL.set(
    'Membership expires',
    'Vervaldatum lidmaatschap'
)
TranslationsNL.set(
    'After your registration has been approved, you must pay your ticket within 24 hours, or your spot will go to the next person in line.',
    'Nadat je registratie goedgekeurd is, moet je het ticket binnen de 24 uren betalen of je plek in de rij zal afgegeven worden aan de volgende persoon.'
)
TranslationsNL.set(
    'A ticket for this event costs €[VAR_PRICE].',
    'Een ticket voor dit evenement kost €[VAR_PRICE].'
)
TranslationsNL.set(
    'A ticket for this event costs €[VAR_PRICE].',
    'Een ticket voor dit evenement kost €[VAR_PRICE].'
)
TranslationsNL.set(
    'Register for this event',
    'Registreer voor dit evenement'
)
TranslationsNL.set(
    'Ticket info',
    'Ticket informatie'
)
TranslationsNL.set(
    'Select language',
    'Kies taal'
)
TranslationsNL.set(
    'Manage event',
    'Beheer evenement'
)
TranslationsNL.set(
    'Manage event:',
    'Beheer evenement:'
)
TranslationsNL.set(
    'Cancel',
    'Annuleren'
)
TranslationsNL.set(
    'Select organisation',
    'Kies organisatie'
)
TranslationsNL.set(
    'Search',
    'Zoeken'
)
TranslationsNL.set(
    'Go to dark mode',
    'Gebruik nachtmodus'
)
TranslationsNL.set(
    'Go to light mode',
    'Gebruik dagmodus'
)
TranslationsNL.set(
    'Planned events',
    'Geplande evenementen'
)
TranslationsNL.set(
    'Create',
    'Toevoegen'
)
TranslationsNL.set(
    'Find your supporters club!',
    'Vindt uw supportersclub!'
)
TranslationsNL.set(
    'Insert a club name...',
    'Vul een naam in...'
)
TranslationsNL.set(
    'Search anything...',
    'Zoek op alles...'
)
TranslationsNL.set(
    'Create organisation',
    'Maak organisatie'
)
TranslationsNL.set(
    'Pick a color for your organisation',
    'Kies een kleur voor uw organisatie'
)
TranslationsNL.set(
    'Only grant loyalty points to people who have actually participated',
    'Geef enkel loyaliteitspunten aan mensen die effectief deelgenomen hebben'
)
TranslationsNL.set(
    'This requires extra checks from organisation administrators',
    'Dit vereist extra acties van organisatie medewerkers per evenement'
)
TranslationsNL.set(
    'Allow members to view events that have already passed',
    'Sta leden toe om te evenementen die al reeds afgelopen zijn op te zoeken'
)
TranslationsNL.set(
    'Use loyalty points for who will be selected/allowed to participate in an event',
    'Gebruik standaard loyaliteitspunten om te bepalen wie mag deelnemen aan een evenement'
)
TranslationsNL.set(
    'Disabling this will use the first come first served principe for events',
    'Dit uitschakelen zal ervoor zorgen dat de tickets verdeeld worden in volgorde van de registratie volgorde'
)
TranslationsNL.set(
    'Path for website',
    'Pad voor url'
)
TranslationsNL.set(
    'Url to Logo',
    'Link naar afbeelding'
)
TranslationsNL.set(
    'Short name',
    'Verkorte naam'
)
TranslationsNL.set(
    'Default meetup address for events',
    'Standaard samenkomst punt voor evenementen'
)
TranslationsNL.set(
    'Default meetup address for events',
    'Standaard samenkomst punt voor evenementen'
)
TranslationsNL.set(
    'My tickets',
    'Mijn tickets'
)
TranslationsNL.set(
    'Create event',
    'Maak evenement'
)
TranslationsNL.set(
    'It looks like this organisation has no events!',
    'Het lijkt erop dat deze organisatie geen evenementen heeft!'
)
TranslationsNL.set(
    'Log out',
    'Uitloggen'
)
TranslationsNL.set(
    'Change of organisation',
    'Verander van organisatie'
)
TranslationsNL.set(
    'Change language',
    'Wijzig taal'
)
TranslationsNL.set(
    'Draft events',
    'Voorlopige evenementen'
)
TranslationsNL.set(
    'Organisation settings',
    'Organisatie instellingen'
)
TranslationsNL.set(
    'About page preview',
    'Voorbeeld pagina "Over ons"'
)
TranslationsNL.set(
    'Mail',
    'Mail'
)
TranslationsNL.set(
    'Payments',
    'Betalingen'
)
TranslationsNL.set(
    'Organisation',
    'Organisatie'
)
TranslationsNL.set(
    'Event',
    'Evenement'
)
TranslationsNL.set(
    'Memberships',
    'Lidmaatschappen'
)
TranslationsNL.set(
    'Archive the event automatically after it has ended (days)',
    'Archiveer het evenement automatisch als het afgelopen is (dagen)'
)
TranslationsNL.set(
    'Save settings',
    'Instellingen opslagen'
)
TranslationsNL.set(
    'About page',
    '"Over ons" pagina'
)
TranslationsNL.set(
    'click for preview',
    'Zie voorbeeld'
)
TranslationsNL.set(
    'Default meetup address',
    'Basis ontmoetingsplaats'
)
TranslationsNL.set(
    'Use first come first served principle',
    'Gebruik "first come, first served principle"'
)
TranslationsNL.set(
    'Only count members as participated for an event after manually checking them in (during or after each event)',
    'Markeer gebruikers enkel als "deelgenomen" door ze manueel af te vinken (dit kan gedurende of na elk event gebeuren)'
)
TranslationsNL.set(
    'Create membership',
    'Maak lidmaatschap'
)
TranslationsNL.set(
    'Current memberships',
    'Huidige lidmaatschappen'
)
TranslationsNL.set(
    'Manage user memberships',
    'Beheer gebruiker-lidmaatschappen'
)
TranslationsNL.set(
    'Set as paid',
    'Zet als betaald'
)
TranslationsNL.set(
    'Remove payment',
    'Verwijder betaling'
)
TranslationsNL.set(
    'Save settings',
    'Instellingen opslagen'
)
TranslationsNL.set(
    'Public key',
    'Publieke sleutel (Public key)'
)
TranslationsNL.set(
    'Secret key',
    'Geheime sleutel (Secret key)'
)
TranslationsNL.set(
    'Membership',
    'Lidmaatschap'
)
TranslationsNL.set(
    'Paid',
    'Betaald'
)
TranslationsNL.set(
    'Name',
    'Naam'
)
TranslationsNL.set(
    'Infinite',
    'Oneindig'
)
TranslationsNL.set(
    'Bewerken',
    'Wijzigen'
)
TranslationsNL.set(
    'Points',
    'Punten'
)
TranslationsNL.set(
    'Price',
    'Prijs'
)
TranslationsNL.set(
    'Member limit',
    'Deelnemerslimiet'
)
TranslationsNL.set(
    'Expires',
    'Tot'
)
TranslationsNL.set(
    'Starts',
    'Van'
)
TranslationsNL.set(
    'Reset',
    'Resetten'
)
TranslationsNL.set(
    'Description',
    'Beschrijving'
)
TranslationsNL.set(
    'Set 0 for infinite',
    'Zet 0 voor oneindig'
)
TranslationsNL.set(
    'Points for buying',
    'Punten om te kopen'
)
TranslationsNL.set(
    'Limit of members',
    'Limiet van leden'
)
TranslationsNL.set(
    'Fee to join',
    'Bedrag voor aansluiting'
)
TranslationsNL.set(
    'Fee',
    'Bedrag'
)
TranslationsNL.set(
    'Last day of season',
    'Laatste dag van seizoen'
)
TranslationsNL.set(
    'Created on',
    'Aangemaakt op'
)
TranslationsNL.set(
    'Payment status',
    'Status betaling'
)
TranslationsNL.set(
    'Download',
    'Downloaden'
)
TranslationsNL.set(
    'Current organisation only',
    'Enkel huidige organisatie'
)
TranslationsNL.set(
    'Tickets',
    'Tickets'
)
TranslationsNL.set(
    'Members',
    'Leden'
)
TranslationsNL.set(
    'Admin',
    'Administrator'
)
TranslationsNL.set(
    'Browse',
    'Bladeren'
)
TranslationsNL.set(
    'Home',
    'Start'
)
TranslationsNL.set(
    'Title',
    'Titel'
)
TranslationsNL.set(
    'Select event type',
    'Selecteer type evenement'
)
TranslationsNL.set(
    'Create event',
    'Maak evenement'
)
TranslationsNL.set(
    'You must select a type',
    'U moet een type selecteren'
)
TranslationsNL.set(
    'Event title must have at least 2 characters',
    'Titel van evenement moet minsten 2 karakters bevatten'
)
TranslationsNL.set(
    'Display information',
    'Visuele informatie'
)
TranslationsNL.set(
    'Settings',
    'Instellingen'
)
TranslationsNL.set(
    'Banner (url)',
    'Banner (url)'
)
TranslationsNL.set(
    'Go back to event',
    'Ga terug naar evenement'
)
TranslationsNL.set(
    'Save changes',
    'Wijzigingen opslagen'
)
TranslationsNL.set(
    'Mark participants as (un)attended',
    'Markeer deelnemers als (niet) aanwezig'
)
TranslationsNL.set(
    'Launch event',
    'Lanceer evenement'
)
TranslationsNL.set(
    'Start of event',
    'Begin evenement'
)
TranslationsNL.set(
    'End of event',
    'Einde evenement'
)
TranslationsNL.set(
    'Limit of participants (0 is infinite)',
    'Limiet van deelnemers (0 is oneindig)'
)
TranslationsNL.set(
    'Limit of registrations (0 is infinite)',
    'Limiet van registraties (0 is oneindig)'
)
TranslationsNL.set(
    'Start of registrations',
    'Begin van registraties'
)
TranslationsNL.set(
    'Upcoming events',
    'Volgende evenementen'
)
TranslationsNL.set(
    'Next upcoming event',
    'Volgend evenement'
)
TranslationsNL.set(
    'This event has a limit of [VAR_AMOUNT] registrations.',
    'Dit evenement heeft een limiet van [VAR_AMOUNT] registraties.'
)
TranslationsNL.set(
    'This event works with the loyalty principle. Meaning that your chances increase by the amount of events you have attended.',
    'Dit evenement werkt met het loyaliteits principe. Dit betekent dat je kansen voor een ticket samen groeien met het aantal evenementen waaraan je deelneemt.'
)
TranslationsNL.set(
    'This event has a limit of [VAR_AMOUNT] participants.',
    'Dit evenement heeft een limiet van [VAR_AMOUNT] deelnemers.'
)
TranslationsNL.set(
    'Upcoming event',
    'Volgend evenement'
)
TranslationsNL.set(
    'Next upcoming event',
    'Volgend evenement'
)
TranslationsNL.set(
    'End of registrations',
    'Einde van registraties'
)
TranslationsNL.set(
    'No results found!',
    'Geen resultaten gevonden!'
)
TranslationsNL.set(
    'No tickets found!',
    'Geen tickets gevonden!'
)
TranslationsNL.set(
    'Event settings',
    'Evenement instellingen'
)
TranslationsNL.set(
    'Sign in',
    'Aanmelden'
)
TranslationsNL.set(
    'Register',
    'Registreren'
)
TranslationsNL.set(
    'Password',
    'Wachtwoord'
)
TranslationsNL.set(
    'Forgot your password?',
    'Wachtwoord vergeten?'
)
TranslationsNL.set(
    'Authenticating',
    'Gebruikersgegevens ophalen'
)
TranslationsNL.set(
    'Price of ticket (€)',
    'Prijs ticket (€)'
)
TranslationsNL.set(
    'Use first come first served principe. (When disabled a principe based on loyalty points will be used)',
    'Gebruik FIFO methode. (Indien nee wordt er een puntensysteem gebruikt op basis van deelnames en registraties)'
)
TranslationsNL.set(
    'Ticket settings',
    'Instellingen tickets'
)
TranslationsNL.set(
    'Details for tickets',
    'Ticket gegevens'
)
TranslationsNL.set(
    'Details for event',
    'Evenement gegevens'
)
TranslationsNL.set(
    'Details for registrations',
    'Registratie gegevens'
)
TranslationsNL.set(
    'Ticket assignment',
    'Verdeelmethode'
)
TranslationsNL.set(
    'First come, first served',
    'Eerst geregistreerd, eerst gekregen'
)
TranslationsNL.set(
    'Loading organisation',
    'Organisatie ophalen'
)
TranslationsNL.set(
    'Loyalty system',
    'Puntensysteem'
)
TranslationsNL.set(
    'Cancel event',
    'Verwijder evemement'
)
TranslationsNL.set(
    'Limit',
    'Limiet'
)
TranslationsNL.set(
    'Unlimited',
    'Oneindig'
)
TranslationsNL.set(
    '',
    ''
)
TranslationsNL.set(
    '',
    ''
)
