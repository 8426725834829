import DefaultPageLayout from "../../components/layout/DefaultPageLayout";
import {Grid} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import SetPublicPageInfo from "../../components/layout/SetPublicPageInfo";
import Box from "@mui/joy/Box";

export default function Error404() {
    return <Box sx={{bgcolor: 'background.surface', height: '100vh'}}>
        <Grid container justifyContent="center">
            <SetPublicPageInfo/>
            <DefaultPageLayout title={"Error 404"}>
                <Typography level={"body-lg"}>It looks empty over here...</Typography>
                <Typography level={"body-sm"} sx={{mb: 3}}>We couldn't find the page you're looking for.</Typography>
            </DefaultPageLayout>
        </Grid>
    </Box>
}
