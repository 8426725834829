import Table from "@mui/joy/Table";
import useTranslation from "../../../../hooks/contexts/TranslationContext";
import {useGetCollection} from "../../../../hooks/useHttp";
import useOrganisation from "../../../../hooks/contexts/OrganisationContext";
import {useParams} from "react-router-dom";
import {LinearProgress} from "@mui/joy";
import Error500 from "../../../../pages/errors/Error500";
import {compare, dateTimeString} from "../../../../utils/date";
import IconButton from "@mui/joy/IconButton";
import {DeleteIcon} from "../../../layout/CrudIcons";
import {deleteObject, postObject} from "../../../../functions/objectService";
import useNotifications from "../../../../hooks/contexts/NotificationContext";
import Input from "@mui/joy/Input";
import AddIcon from "@mui/icons-material/Add";
import {useState} from "react";

const defaultRequest = {remarks: "", amount: 0}
export default function PointsHistory() {
    const {ORG_URI} = useOrganisation()
    const {t} = useTranslation()
    const {userId} = useParams<{ userId: string }>()
    const {
        data,
        firstLoading,
        error,
        refresh
    } = useGetCollection<PointsHistoryRecord>(`${ORG_URI}/Members/Points/${userId}`)
    const {error: errorNot, success} = useNotifications()
    const [request, setRequest] = useState<{ remarks: string, amount: number }>(defaultRequest)

    if (firstLoading)
        return <LinearProgress/>
    if (error)
        return <Error500/>

    async function onDelete(rowId: string | null) {
        try {
            if (rowId === null) return;
            await deleteObject(`${ORG_URI}/Members/Points/${rowId}`)
            await refresh()
            success(t("Successfully deleted points"))
        } catch (e: any) {
            errorNot(`${e}`)
        }
    }

    async function onSubmit() {
        try {
            console.log(request)
            if (request.amount === 0)
                throw new Error(t("0 is not a correct value"))
            if (request.remarks?.length < 3)
                throw new Error(t("At least 3 characters are required for the remarks field"))
            await postObject(`${ORG_URI}/Members/Points/${userId}`, {
                ...request
            })
            await refresh()
            success(t("Successfully added points"))
            setRequest(defaultRequest)
        } catch (e: any) {
            errorNot(`${e}`)
        }
    }

    return <Table>
        <thead>
        <tr>
            <th>#</th>
            <th>{t("Reason")}</th>
            <th>{t("Points")}</th>
            <th>{t("Date")}</th>
            <th style={{width: 60}}></th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td colSpan={3}>
                <Input
                    value={request.remarks}
                    placeholder={t("Reason")}
                    onChange={(e) => setRequest({...request, remarks: e.target.value})}
                />
            </td>
            <td>
                <Input
                    value={request.amount}
                    placeholder={t("Points")}
                    type={"number"}
                    onChange={(e) => setRequest({...request, amount: Number.parseFloat(e.target.value)})}
                />
            </td>
            <td>
                <IconButton color={"success"} onClick={onSubmit}>
                    <AddIcon/>
                </IconButton>
            </td>
        </tr>
        {data
            .sort((a, b) => compare(a.dateTime, b.dateTime))
            .map((row, i) => <tr key={i}>
                <td>{i + 1}</td>
                <td><small>{row.reason}</small></td>
                <td>{row.points}</td>
                <td>{dateTimeString(row.dateTime)}</td>
                <td>
                    {row.isDeletable && <IconButton color={"danger"} onClick={() => onDelete(row.id)}>
                        <DeleteIcon/>
                    </IconButton>}
                </td>
            </tr>)}
        </tbody>
    </Table>
}

interface PointsHistoryRecord {
    id: string | null
    reason: string
    dateTime: Date
    points: number
    isDeletable: boolean
}
