import useOrganisation from "../../hooks/contexts/OrganisationContext";
import EventsList from "./event/List";
import AboutPage from "../../components/layout/AboutPage";
import SubscribeButton from "../../components/organisation/SubscribeButton";


export default function OrganisationHome() {
    const {organisation} = useOrganisation()

    return <>
        <SubscribeButton/>
        {organisation.settings.aboutPage.length > 50 && <AboutPage content={organisation.settings.aboutPage}/>}
        <EventsList/>
    </>
}
