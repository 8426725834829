import * as React from "react";
import {ReactNode} from "react";
import JoyModal from "@mui/joy/Modal";
import {Sheet} from "@mui/joy";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import useTranslation from "../../../hooks/contexts/TranslationContext";
import {SxProps} from "@mui/joy/styles/types";

export default function Modal({children, open, onClose, title, sx}: {
    children: ReactNode,
    open: boolean,
    onClose: VoidFunction,
    title?: string,
    sx?: SxProps
}) {
    const {t} = useTranslation()

    return <JoyModal
        open={open}
        onClose={onClose}
        sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
    >
        <Sheet
            variant="outlined"
            sx={{
                p: 3,
                m: 2,
                maxWidth: '90vw',
                maxHeight: '90vh',
                borderRadius: 'md',
                boxShadow: 'lg',
                overflowY: 'auto',
                ...sx
            }}>
            <ModalClose/>
            {title !== undefined && <Typography level={"h4"}>{t(title)}</Typography>}
            {children}
        </Sheet>
    </JoyModal>
}
