import {ReactNode} from "react";
import useTranslation from "../../../hooks/contexts/TranslationContext";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/joy";
import Avatar from "@mui/joy/Avatar";
import ListItemContent from "@mui/joy/ListItemContent";
import Typography from "@mui/joy/Typography";

export default function ManageUserDataAccordion(props: {
    title: string,
    desc: string,
    icon: ReactNode,
    children: ReactNode,
    color: "primary" | "warning" | "danger" | "success" | "neutral" | undefined,
    active: string | null,
    setActive: (e: string | null) => void
}) {
    const {t} = useTranslation()

    const isActive = props.active === props.title

    return <Accordion expanded={isActive} onChange={() => props.setActive(isActive ? null : props.title)}>
        <AccordionSummary>
            <Avatar color={props.color}>
                {props.icon}
            </Avatar>
            <ListItemContent>
                <Typography level="title-md">{t(props.title)}</Typography>
                <Typography level="body-sm">
                    {t(props.desc)}
                </Typography>
            </ListItemContent>
        </AccordionSummary>
        <AccordionDetails>{props.children}</AccordionDetails>
    </Accordion>
}
