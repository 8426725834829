import Membership from "./Membership";
import {EmptyGuid} from "../utils/string";
import {ApplicationUser} from "./ApplicationUser";

export default interface ClubEvent {
    id: string
    title: string
    expandedTitle: string
    description: string
    status: ClubEventPhase
    startEvent: Date
    endEvent: Date
    startRegistrations: Date
    endRegistrations: Date
    fee: number
    limitOfParticipants: number
    limitOfRegistrations: number
    useFirstComeFirstServedPrinciple: boolean
    membershipRequired: Membership | null
    eventType: ClubEventType
    banner: string
    eventMeetupAddress: string | null
    registrations: UserEventRegistration[]
}

export function getLimitString(amount: number | null | undefined) {
    if (amount === null || amount === undefined || amount === 0) return 'Unlimited'
    return amount
}

export enum ClubEventPhase {
    Draft = 0,
    SetupCompleted = 1,
    Cancelled = 2,
    Archived = 3
}

export enum EventModifyPhase {
    DisplayInfo,
    Settings,
    LaunchEvent,
    ManageRegistrations,
    LinkTicketsToRegistrations,
    SetParticipantsAsAttendedAndMarkAsFinished
}

export interface EventModifyTabProps {
    clubEvent: ClubEvent
    refresh: () => void
}

export interface UserEventRegistration {
    user: ApplicationUser
    event: ClubEvent
    ticket: Ticket
    participated: boolean
    orgId: string | null | undefined
    eventId: string | null | undefined
    eventTitle: string | null | undefined
}

export interface Ticket {
    paid: boolean
    attachment: any
    id: string
    modifiedOn: Date
    createdOn: Date
}

export interface UserIdWithPoints {
    userId: string
    points: number
}

export interface UserEventRegistrationWithPoints {
    registration: UserEventRegistration
    points: UserIdWithPoints
}

export function orderByEventRegistrationPoints(a: number, b: number) {
    return b - a
}

export function EmptyClubEvent(): ClubEvent {
    return {
        id: EmptyGuid,
        title: "",
        expandedTitle: "",
        description: "",
        status: ClubEventPhase.Draft,
        banner: "",
        fee: 0,
        eventMeetupAddress: null,
        startRegistrations: new Date(),
        endRegistrations: new Date(),
        limitOfParticipants: 0,
        eventType: {
            id: null,
            name: '',
            pointsOnParticipate: 0,
            pointsOnRegister: 0
        },
        limitOfRegistrations: 0,
        endEvent: new Date(),
        startEvent: new Date(),
        membershipRequired: null,
        registrations: [],
        useFirstComeFirstServedPrinciple: false
    }
}

export interface ClubEventType {
    id: string | null
    name: string
    pointsOnParticipate: number
    pointsOnRegister: number
}

export function sortEventTypeAsc(a: ClubEventType, b: ClubEventType) {
    return (a.pointsOnRegister + a.pointsOnParticipate) - (b.pointsOnRegister + b.pointsOnParticipate)
}

export function sortEventTypeDesc(a: ClubEventType, b: ClubEventType) {
    return -sortEventTypeAsc(a, b)
}
