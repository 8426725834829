import {useForm} from "react-hook-form";
import TabSettingLayout from "./TabSettingLayout";
import InputControl from "../../form/InputControl";
import IosSwitchControl from "../../form/IosSwitchControl";
import {ReactNode, useState} from "react";
import useOrganisation from "../../../hooks/contexts/OrganisationContext";
import {putObject} from "../../../functions/objectService";
import useNotifications from "../../../hooks/contexts/NotificationContext";
import Button from "@mui/joy/Button";
import {CenterDiv} from "../../layout/FlexDiv";
import {UpdateIcon} from "../../layout/CrudIcons";
import useTranslation from "../../../hooks/contexts/TranslationContext";

export default function EventSettings() {
    const {organisation, ORG_URI, refresh} = useOrganisation()
    const [settings, setSettings] = useState(organisation.settings)
    const {register, getValues} = useForm()
    const {success, error} = useNotifications()
    const {t} = useTranslation()

    function handleChange(prop: string, value: boolean) {
        setSettings({...settings, [prop]: value})
    }

    async function onSubmit() {
        try {
            await putObject(`${ORG_URI}/settings/event`, {
                ...settings,
                defaultEventMeetupAddress: getValues("address"),
            })
            success("Successfully saved organisation settings")
            refresh()
        } catch (e: any) {
            error(`An error has occurred whilst updating your organisation settings: ${e}`)
        }
    }

    return <>
        <TabSettingLayout question={t("Default meetup address")}>
            <InputControl register={register("address", {value: settings.defaultEventMeetupAddress})}/>
        </TabSettingLayout>
        <TabSettingLayout question={t("Use first come first served principle")}>
            <SwitchAlignLeft>
                <IosSwitchControl
                    checked={settings.defaultUseFirstComeFirstServedPrinciple}
                    setChecked={() => handleChange("defaultUseFirstComeFirstServedPrinciple", !settings.defaultUseFirstComeFirstServedPrinciple)}/>
            </SwitchAlignLeft>
        </TabSettingLayout>
        <TabSettingLayout
            question={t("Only count members as participated for an event after manually checking them in (during or after each event)")}>
            <SwitchAlignLeft>
                <IosSwitchControl
                    checked={settings.verifyWhoParticipated}
                    setChecked={() => handleChange("verifyWhoParticipated", !settings.verifyWhoParticipated)}/>
            </SwitchAlignLeft>
        </TabSettingLayout>
        <CenterDiv>
            <Button color={"success"} onClick={onSubmit}><UpdateIcon/> Save settings</Button>
        </CenterDiv>
    </>
}

export function SwitchAlignLeft(props: { children: ReactNode }) {
    return <div style={{width: "fit-content", marginLeft: 10}}>
        {props.children}
    </div>
}
