import {Accordion, AccordionDetails, AccordionSummary, Sheet} from "@mui/joy";
import useTranslation from "../../../../hooks/contexts/TranslationContext";
import Table from "@mui/joy/Table";
import {useGetCollection} from "../../../../hooks/useHttp";
import {UserMembership} from "../../../../types/Membership";
import useOrganisation from "../../../../hooks/contexts/OrganisationContext";
import LoadingPage from "../../../../pages/LoadingPage";
import Error500 from "../../../../pages/errors/Error500";
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import {compare} from "../../../../utils/date";
import Button from "@mui/joy/Button";
import {useState} from "react";
import useNotifications from "../../../../hooks/contexts/NotificationContext";
import {putObject} from "../../../../functions/objectService";

export default function ManageUserMemberships() {
    const {t} = useTranslation()
    const {ORG_URI} = useOrganisation()
    const {data, firstLoading, error, refresh} = useGetCollection<UserMembership>(`${ORG_URI}/UserMemberships/Active`)
    const [btnLoading, setBtnLoading] = useState(false)
    const {success, error: errorNot} = useNotifications()

    if (firstLoading)
        return <LoadingPage/>
    if (error)
        return <Error500/>

    async function togglePayment(um: UserMembership) {
        try {
            setBtnLoading(true)
            await putObject(`${ORG_URI}/UserMemberships/Payment`, {
                umId: um.id,
                value: !um.paid
            })
            success(t(`Successfully modified payment of ${um.user.fullName}`))
            await refresh()
        } catch (e: any) {
            errorNot(e)
        } finally {
            setBtnLoading(false)
        }
    }

    return <Accordion>
        <AccordionSummary>{t("Manage user memberships")}</AccordionSummary>
        <AccordionDetails>
            <Sheet>
                <Table>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>{t("Name")}</th>
                        <th>{t("Membership")}</th>
                        <th>{t("Paid")}</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {data
                        .sort((a, b) => compare(a.user.registerDate, b.user.registerDate))
                        .map((um, index) => <tr key={um.id}>
                            <td>{index + 1}</td>
                            <td>{um.user.fullName}</td>
                            <td>{um.membership.title}</td>
                            <td>{um.paid
                                ? <CheckIcon color={"success"}/>
                                : <ClearIcon color={"error"}/>}</td>
                            <td>
                                <Button disabled={btnLoading} onClick={() => togglePayment(um)}>
                                    {t(um.paid ? "Remove payment" : "Set as paid")}
                                </Button>
                            </td>
                        </tr>)}
                    </tbody>
                </Table>
            </Sheet>
        </AccordionDetails>
    </Accordion>
}