import Typography from "@mui/joy/Typography";
import useTranslation from "../../../hooks/contexts/TranslationContext";

export default function FormCardHeader({title, subtitle}: { title: string, subtitle?: string }) {
    const {t} = useTranslation()
    return <>
        {title && <Typography level={"title-lg"}>{t(title)}</Typography>}
        {subtitle && <Typography level={"body-sm"}>
            {t(subtitle)}
        </Typography>}
    </>
}
