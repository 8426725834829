import TabSettingLayout from "./TabSettingLayout";
import Input from "@mui/joy/Input";
import * as React from "react";
import {useState} from "react";
import useOrganisation from "../../../hooks/contexts/OrganisationContext";
import useTranslation from "../../../hooks/contexts/TranslationContext";
import useNotifications from "../../../hooks/contexts/NotificationContext";
import {putObject} from "../../../functions/objectService";
import {CssVarsProvider} from "@mui/joy/styles";
import {getSliderTheme} from "../../../utils/theme";
import RichEditor from "../../form/RichEditor";
import Button from "@mui/joy/Button";
import {SaveIcon, ViewIcon} from "../../layout/CrudIcons";
import Modal from "../../layout/modal/Modal";
import PrintHtml from "../../layout/PrintHtml";
import {CenterDiv} from "../../layout/FlexDiv";
import {Slider} from "@mui/joy";
import {SwitchAlignLeft} from "./EventSettings";
import IosSwitchControl from "../../form/IosSwitchControl";
import useScreen from "../../../hooks/useScreen";

export function PublicDisplaySettings() {
    const {organisation, ORG_URI, refresh} = useOrganisation()
    const {t} = useTranslation()
    const {success, error} = useNotifications()
    const [logo, setLogo] = useState(organisation.logo)
    const [banner, setBanner] = useState(organisation.banner)
    const [aboutPage, setAboutPage] = useState(organisation.settings.aboutPage)
    const [mustConfirmNewMembers, setMustConfirmNewMembers] = useState(organisation.settings.mustConfirmNewMembers)
    const [orgColor, setOrgColor] = useState(organisation.settings.hslDefaultColor)
    const [archiveAfterDays, setArchiveAfterDays] = useState(organisation.settings.archiveEventsAfterDays)
    const {PhoneOrTablet} = useScreen()

    async function onSubmit() {
        try {
            await putObject(`${ORG_URI}/settings/public`, {
                logo,
                banner,
                aboutPage,
                hslDefaultColor: orgColor,
                mustConfirmNewMembers,
                archiveEventsAfterDays: archiveAfterDays,
            })
            success(t("Successfully saved organisation settings"))
            refresh()
        } catch (e: any) {
            error(`An error has occurred whilst updating your organisation settings: ${e}`)
        }
    }

    return <>
        <TabSettingLayout question={t("Logo")}>
            <Input type={"string"}
                   sx={{ml: 1}}
                   value={logo}
                   onChange={(e) => setLogo(e.target.value)}/>
        </TabSettingLayout>
        <TabSettingLayout question={t("Banner")}>
            <Input type={"string"}
                   sx={{ml: 1}}
                   value={banner}
                   onChange={(e) => setBanner(e.target.value)}/>
        </TabSettingLayout>
        <TabSettingLayout question={t("Archive the event automatically after it has ended for days")}>
            <Input type={"number"}
                   sx={{ml: 1}}
                   value={archiveAfterDays}
                   onChange={(e) => setArchiveAfterDays(Number.parseInt(e.target.value))}/>
        </TabSettingLayout>
        {!PhoneOrTablet && <TabSettingLayout question={t("Accent color for website")}>
            <CssVarsProvider theme={getSliderTheme(orgColor)}>
                <Slider
                    min={0}
                    max={360}
                    value={orgColor}
                    onChange={(_, v) => setOrgColor(v as number)}/>
            </CssVarsProvider>
        </TabSettingLayout>}
        <TabSettingLayout question={t("Members must be verified before they can register for a subscription")}>
            <SwitchAlignLeft>
                <IosSwitchControl
                    checked={mustConfirmNewMembers}
                    setChecked={() => setMustConfirmNewMembers(!mustConfirmNewMembers)}/>
            </SwitchAlignLeft>
        </TabSettingLayout>
        <TabSettingLayout question={<PreviewAboutPage content={aboutPage}/>}>
            <></>
        </TabSettingLayout>
        <CssVarsProvider theme={getSliderTheme(organisation.settings.hslDefaultColor)}>
            <RichEditor
                value={aboutPage}
                onChange={e => setAboutPage(e)}
            />
        </CssVarsProvider>
        <CenterDiv>
            <Button color={"success"} onClick={onSubmit} sx={{mt: 2}}><SaveIcon/> {t("Save settings")}</Button>
        </CenterDiv>
    </>
}

function PreviewAboutPage({content}: { content: string }) {
    const {t} = useTranslation()
    const [open, setOpen] = useState(false)

    return <>
        {t("About page")} <Button color={"neutral"} variant={"plain"} onClick={() => setOpen(true)}>
        <ViewIcon/> {t("click for preview")}
    </Button>
        <Modal title={"About page preview"} open={open} onClose={() => setOpen(false)} sx={{minWidth: 500}}>
            <PrintHtml html={content}/>
        </Modal>
    </>
}
