import * as React from 'react';
import List from '@mui/joy/List';
import ListSubheader from '@mui/joy/ListSubheader';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListItemContent from '@mui/joy/ListItemContent';
import useTranslation from "../../../hooks/contexts/TranslationContext";
import useOrganisation from "../../../hooks/contexts/OrganisationContext";
import CreateEventFormButton from "../../organisation/event/ModalForm";

// Icons import
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import StadiumIcon from '@mui/icons-material/Stadium';
import PeopleIcon from '@mui/icons-material/People';

export default function Navigation() {
    const {orgNavigate, isOrgAdmin} = useOrganisation()
    const {t} = useTranslation()
    return <List size="sm" sx={{'--ListItem-radius': '8px', '--List-gap': '4px'}}>
        <ListItem nested>
            <ListSubheader>
                {t("Browse")}
            </ListSubheader>
            <List
                aria-labelledby="nav-list-browse"
                sx={{
                    '& .JoyListItemButton-root': {p: '8px'},
                }}
            >
                <ListItem>
                    <ListItemButton onClick={() => orgNavigate("")}>
                        <ListItemDecorator>
                            <HomeRoundedIcon/>
                        </ListItemDecorator>
                        <ListItemContent>{t("Home")}</ListItemContent>
                    </ListItemButton>
                </ListItem>
                <ListItemButton onClick={() => orgNavigate("/Account/Tickets")}>
                    <ListItemDecorator>
                        <StadiumIcon/>
                    </ListItemDecorator>
                    <ListItemContent>{t("My tickets")}</ListItemContent>
                </ListItemButton>
            </List>
        </ListItem>
        {isOrgAdmin && <ListItem nested>
            <ListSubheader>
                {t("Admin")}
            </ListSubheader>
            <List
                aria-labelledby="nav-list-browse"
                sx={{
                    '& .JoyListItemButton-root': {p: '8px'},
                }}
            >
                <CreateEventFormButton/>
                <ListItem>
                    <ListItemButton onClick={() => orgNavigate("/members")}>
                        <ListItemDecorator>
                            <PeopleIcon/>
                        </ListItemDecorator>
                        <ListItemContent>{t("Members")}</ListItemContent>
                    </ListItemButton>
                </ListItem>
            </List>
        </ListItem>}
    </List>
}
