import OrderList from "../../components/layout/OrderList";
import OrderTable from "../../components/layout/OrderTable";
import DefaultPageLayout from "../../components/layout/DefaultPageLayout";
import useOrganisation from "../../hooks/contexts/OrganisationContext";

export default function ExampleTables() {
    const {organisation} = useOrganisation()

    return <DefaultPageLayout title={organisation?.name ?? 'Example page'}>
        <OrderList/>
        <OrderTable/>
    </DefaultPageLayout>
}
