import useOrganisation from "../../../../hooks/contexts/OrganisationContext";
import IconButton from "@mui/joy/IconButton";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import * as React from "react";
import {Tooltip} from "@mui/joy";
import useTranslation from "../../../../hooks/contexts/TranslationContext";

export default function OrgSettingsBtn() {
    const {t} = useTranslation()
    const {isOrgAdmin, orgNavigate} = useOrganisation()

    if (!isOrgAdmin) return <></>

    return <Tooltip title={t("Organisation settings")}>
        <IconButton onClick={() => orgNavigate("/Settings")} size={"sm"}>
            <SettingsRoundedIcon fontSize={"small"}/>
        </IconButton>
    </Tooltip>
}