import IconButton from "@mui/joy/IconButton";
import * as React from "react";
import {useState} from "react";
import Modal from "../../modal/Modal";
import useTranslation, {LangCode} from "../../../../hooks/contexts/TranslationContext";
import Typography from "@mui/joy/Typography";
import Avatar from "@mui/joy/Avatar";
import {Tooltip} from "@mui/joy";

export default function SwitchLanguageBtn() {
    const {setLanguage, langCode, getLangImg} = useTranslation()
    const [open, setOpen] = useState(false)
    const {t} = useTranslation()

    function handleClick(code: LangCode) {
        setLanguage(code)
        setOpen(false)
    }

    return <>
        <Tooltip title={t("Change language")}>
            <IconButton variant={"soft"} onClick={() => setOpen(true)} sx={{
                "WebkitFilter": "grayscale(60%)", ":hover": {
                    "WebkitFilter": "grayscale(0%)"
                }
            }}>
                <Avatar variant={"soft"} alt="pick lang" sx={{'--Avatar-size': '24px'}} src={getLangImg(langCode)}/>
            </IconButton>
        </Tooltip>
        <Modal open={open} onClose={() => setOpen(false)} title={"Select language"} sx={{minWidth: 200}}>
            <FlagButton title={"English"} onClick={handleClick} code={LangCode.en}/>
            <FlagButton title={"Nederlands"} onClick={handleClick} code={LangCode.nl}/>
            <FlagButton title={"français"} onClick={handleClick} code={LangCode.fr}/>
        </Modal>
    </>
}

function FlagButton(props: { onClick: (e: LangCode) => void, code: LangCode, title: string }) {
    const {getLangImg} = useTranslation()

    const boxSize = 120
    const imgSize = 70

    return <IconButton variant="soft" sx={{m: 1}} onClick={() => props.onClick(props.code)}>
        <div style={{padding: 10, height: boxSize, width: boxSize}}>
            <img src={getLangImg(props.code)}
                 alt={"language img"}
                 style={{width: imgSize, height: imgSize}}/>
            <Typography>{props.title}</Typography>
        </div>
    </IconButton>
}
