import {ReactNode} from "react";
import {Card} from "@mui/joy";
import DefaultPageLayout from "../DefaultPageLayout";

export default function FormCard(props: { children: ReactNode }) {
    return <DefaultPageLayout title={""}>
        <Card sx={{m: 'auto', maxWidth: 600}}>
            {props.children}
        </Card>
    </DefaultPageLayout>
}
