import {ClubEventType, sortEventTypeDesc} from "../../../types/ClubEvent";
import useOrganisation from "../../../hooks/contexts/OrganisationContext";
import {useGetCollection} from "../../../hooks/useHttp";
import {LinearProgress} from "@mui/joy";
import Error500 from "../../../pages/errors/Error500";
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import * as React from "react";

export default function SelectEventType({selected, setType, centerButtons = true}: {
    selected: ClubEventType | null,
    setType: (e: ClubEventType | null) => void,
    centerButtons?: boolean
}) {
    const {organisation} = useOrganisation()
    const {
        data: eventTypes,
        firstLoading: loadingTypes,
        error: typesError,
    } = useGetCollection<ClubEventType>(`/Organisations/${organisation.id}/Events/Types`)

    if (loadingTypes) return <LinearProgress sx={{m: 5}}/>
    if (loadingTypes) return <LinearProgress/>
    if (typesError)
        return <Error500/>

    const classes = centerButtons ? "flexElements centerElements" : "flexElements"

    return <Box sx={{maxHeight: "40vh", overflowY: "auto"}} className={classes}>
        {eventTypes.sort(sortEventTypeDesc).map((type, index) => <IconButton
            color={selected?.id === type.id ? "primary" : "neutral"}
            key={index}
            variant="soft" sx={{m: 1}}
            onClick={() => setType(selected?.id === type.id ? null : type)}>
            <div style={{padding: 10, width: 125}}>
                <Typography>{type.name}</Typography>
            </div>
        </IconButton>)}
        {eventTypes.length === 0 &&
            <Typography level={"title-sm"} sx={{color: "red"}}>
                It looks like you have no types yet,
                you must create one before you can create an event!
            </Typography>}
    </Box>
}