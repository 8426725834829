import {createContext, ReactNode, useContext, useState} from "react";
import {TranslationsNL} from "../../locals/nl";
import {TranslationsFR} from "../../locals/fr";

export enum LangCode {
    'en' = 'en',
    'nl' = 'nl',
    'fr' = 'fr',
}

const TranslationContext = createContext<{
    setLanguage: (code: LangCode) => void,
    t: (key: string) => string,
    getLangImg: (e: LangCode) => string,
    langCode: LangCode
}>({
    setLanguage: () => {
    },
    t: (key: string) => key,
    getLangImg: () => '',
    langCode: LangCode.en
})

const localLangCodeKey = "LANG_CODE"

function getLocalLangCode() {
    const response = (localStorage.getItem(localLangCodeKey) ?? 'en')
    if (response in LangCode) return response as LangCode
    return 'en' as LangCode
}

function storeLangCode(key: LangCode) {
    localStorage.setItem(localLangCodeKey, key)
}

export default function useTranslation() {
    return useContext(TranslationContext)
}

export function TranslationProvider(props: { children: ReactNode }) {
    const [langCode, setLangCode] = useState<LangCode>(getLocalLangCode())

    function setLanguage(code: LangCode) {
        storeLangCode(code)
        setLangCode(code)
    }

    function translate(key: string) {
        const translationFile = getTranslationFile()
        return translationFile.has(key.toLowerCase())
            ? `${translationFile.get(key.toLowerCase())}`
            : key
    }

    function translateDebugMode(key: string) {
        const translationFile = getTranslationFile()
        if (langCode !== LangCode.en && !translationFile.has(key.toLowerCase()))
            console.info(`LANG: '${langCode}', Untranslated word: '${key}'`)
        return translate(key)
    }

    let useTranslateMethod = translate
    if (window.location.href.includes('localhost'))
        useTranslateMethod = translateDebugMode

    function getTranslationFile(): Map<string, string> {
        switch (langCode) {
            case "nl":
                return TranslationsNL
            case "fr":
                return TranslationsFR
            default:
                return new Map<string, string>()
        }
    }

    function getLangImg(code: LangCode) {
        switch (code) {
            case LangCode.nl:
                return 'https://cdn-icons-png.flaticon.com/512/323/323275.png'
            case LangCode.en:
                return 'https://cdn-icons-png.flaticon.com/512/197/197374.png'
            case LangCode.fr:
                return 'https://cdn-icons-png.flaticon.com/512/197/197560.png'
            default:
                return 'https://cdn-icons-png.flaticon.com/512/5720/5720434.png'
        }
    }

    return <TranslationContext.Provider value={{setLanguage, t: useTranslateMethod, getLangImg, langCode}}>
        {props.children}
    </TranslationContext.Provider>
}
