import {Grid} from "@mui/joy";
import useClubEvent from "../../../../hooks/contexts/ClubEventContext";
import InputControl from "../../../form/InputControl";
import {useForm} from "react-hook-form";
import Typography from "@mui/joy/Typography";
import * as React from "react";
import SelectEventType from "../SelectEventType";
import ClubEvent, {ClubEventPhase, ClubEventType} from "../../../../types/ClubEvent";
import IosSwitchControl from "../../../form/IosSwitchControl";
import useTranslation from "../../../../hooks/contexts/TranslationContext";
import TTypography from "../../../layout/joy/TTypography";
import InputDateTimeControl from "../../../form/InputDateTimeControl";
import moment from "moment";

export default function SettingsTab() {
    const {clubEvent, updateEvent} = useClubEvent()
    const {register, getValues} = useForm()
    const {t} = useTranslation()

    function handleChange(key: string, val: any) {
        updateEvent({...clubEvent as ClubEvent, [key]: val})
    }

    return <div>
        <div>
            <Typography level={"title-md"}>Ticket settings</Typography>
            <Grid container>
                <Grid md={5} sm={12}>
                    <InputControl
                        inputProps={{type: "number", disabled: clubEvent?.status !== ClubEventPhase.Draft}}
                        label={t("Price of ticket (€)")}
                        register={register("fee", {
                            value: clubEvent?.fee,
                            onChange: () => handleChange("fee", getValues("fee"))
                        })}/>
                </Grid>
                <Grid md={8} sm={12}>
                    <IosSwitchControl
                        disabled={clubEvent?.status !== ClubEventPhase.Draft}
                        label={t("Use first come first served principe. (When disabled a principe based on loyalty points will be used)")}
                        checked={clubEvent?.useFirstComeFirstServedPrinciple ?? false}
                        setChecked={e => updateEvent({
                            ...clubEvent as ClubEvent,
                            useFirstComeFirstServedPrinciple: e
                        })}/>
                </Grid>
            </Grid>
        </div>
        <div>
            <Typography level={"title-md"}>Registration settings</Typography>
            <Grid container>
                <Grid md={4} sm={6}>
                    <InputDateTimeControl
                        inputProps={{disabled: clubEvent?.status !== ClubEventPhase.Draft}}
                        label={"Start of registrations"}
                        setValue={d => handleChange("startRegistrations", d)}
                        value={clubEvent?.startRegistrations ?? moment().add(1, "day").toDate()}/>
                </Grid>
                <Grid md={4} sm={6}>
                    <InputDateTimeControl
                        label={"End of registrations"}
                        setValue={d => handleChange("endRegistrations", d)}
                        value={clubEvent?.endRegistrations ?? moment().add(1, "day").toDate()}/>
                </Grid>
                <Grid md={4} sm={12}>
                    <InputControl
                        inputProps={{type: "number", disabled: clubEvent?.status !== ClubEventPhase.Draft}}
                        label={t("Limit of registrations (0 is infinite)")}
                        register={register("limitOfRegistrations", {
                            value: clubEvent?.limitOfRegistrations,
                            onChange: () => handleChange("limitOfRegistrations", getValues("limitOfRegistrations"))
                        })}/>
                </Grid>
            </Grid>
        </div>
        <div>
            <Typography level={"title-md"}>{t("Event settings")}</Typography>
            <Grid container>
                <Grid md={4} sm={6}>
                    <InputDateTimeControl
                        label={"Start of event"}
                        setValue={d => handleChange("startEvent", d)}
                        value={clubEvent?.startEvent ?? moment().add(1, "day").toDate()}/>
                </Grid>
                <Grid md={4} sm={6}>
                    <InputDateTimeControl
                        label={"End of event"}
                        setValue={d => handleChange("endEvent", d)}
                        value={clubEvent?.endEvent ?? moment().add(1, "day").toDate()}/>
                </Grid>
                <Grid md={4} sm={12}>
                    <InputControl
                        inputProps={{type: "number"}}
                        label={t("Limit of participants (0 is infinite)")}
                        register={register("limitOfParticipants", {
                            value: clubEvent?.limitOfParticipants,
                            onChange: () => handleChange("limitOfParticipants", getValues("limitOfParticipants"))
                        })}/>
                </Grid>
            </Grid>
        </div>
        <div>
            <TTypography level={"title-md"}>Select event type</TTypography>
            <SelectEventType
                setType={(e: ClubEventType | null) => {
                    if (e !== null)
                        updateEvent({...clubEvent as ClubEvent, eventType: e})
                }}
                centerButtons={false}
                selected={clubEvent?.eventType ?? null}
            />
        </div>
    </div>
}