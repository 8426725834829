import MainRouter from "./routers/MainRouter";
import {AuthProvider} from "./hooks/contexts/Auth";
import {BrowserRouter} from "react-router-dom";
import {SnackbarProvider} from "notistack";
import {NotificationProvider} from "./hooks/contexts/NotificationContext";
import "./media/css/App.css"
import {TranslationProvider} from "./hooks/contexts/TranslationContext";

export default function App() {
    return <TranslationProvider>
        <SnackbarProvider autoHideDuration={5000} maxSnack={5} preventDuplicate={true}
                          anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
            <NotificationProvider>
                <BrowserRouter>
                    <AuthProvider>
                        <MainRouter/>
                    </AuthProvider>
                </BrowserRouter>
            </NotificationProvider>
        </SnackbarProvider>
    </TranslationProvider>
}
