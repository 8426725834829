import React, {createContext, useContext, useEffect, useState} from "react";
import useInterval from "../useInterval";
import useGetSingle from "../useHttp";
import {getObject, postObject} from "../../functions/objectService";
import {useNavigate} from "react-router-dom";
import Membership from "../../types/Membership";
import Organisation from "../../types/Organisation";
import {INTERVALS} from "../../utils/globals";
import useNotifications from "./NotificationContext";
import LoadingPage from "../../pages/LoadingPage";
import {greyTheme} from "../../utils/theme";
import {CssVarsProvider} from "@mui/joy/styles";

export interface UserInfo {
    id: string
    username: string
    firstname: string
    lastname: string
    avatar: string | null
    memberships: Membership[]
    following: Organisation[]
}

const AuthContext = createContext<{
    user: UserInfo | null
    isAuthorized: () => boolean
    roles: string[]
    forceAuthCheck: () => void
    hasRole: (role: string) => boolean
    logout: () => void
    login: (u: string, p: string) => void
    register: (u: string, p: string, firstName: string, lastName: string, insuranceNumber: string) => void
}>({
    user: null,
    isAuthorized: () => false,
    roles: [],
    forceAuthCheck: () => {
    },
    hasRole: () => false,
    logout: () => {
    },
    login: () => {
    },
    register: () => {
    },
})

export const useAuth = () => useContext(AuthContext)

export function AuthProvider(props: { children: React.ReactNode }) {
    const {data, refresh, hasLoaded, firstLoading} = useGetSingle<{
        authenticated: boolean,
        roles: [] | null,
        user: UserInfo | null
    }>("/auth", {authenticated: false, roles: null, user: null})
    const [user, setUser] = useState<UserInfo | null>(data.user)
    const [roles, setRoles] = useState<string[] | null>(data.roles)
    const navigate = useNavigate()
    const {success, error} = useNotifications()
    useInterval(refresh, INTERVALS.AuthorizationRefresh)

    useEffect(() => {
        setUser(data.user)
        setRoles(data.roles)
    }, [hasLoaded, data])

    function isAuthorized() {
        return !!user
    }

    function hasRole(role: string): boolean {
        return (roles?.filter(e => e.toLowerCase() === role.toLowerCase()).length ?? 0) > 0
    }

    async function logout() {
        await getObject("/auth/logout")
        await refresh()
    }

    async function login(u: string, p: string) {
        postObject("/auth/login", {userName: u, password: p})
            .then(() => {
                success("Successfully logged in")
                refresh()
            })
            .catch(e => {
                error(e.response.data)
            })
    }

    async function register(u: string, p: string, firstName: string, lastName: string, insuranceNumber: string) {
        postObject("/auth/register", {userName: u, password: p, firstName, lastName, insuranceNumber})
            .then(() => {
                navigate("/login")
                success("Successfully registered")
            })
            .catch(e => {
                error(e.response.data)
            })
        await refresh()
    }

    if (firstLoading)
        return <CssVarsProvider theme={greyTheme}>
            <LoadingPage title={"Authenticating"}/>
        </CssVarsProvider>

    return <AuthContext.Provider
        value={{
            user,
            isAuthorized,
            roles: roles ?? [],
            forceAuthCheck: refresh,
            hasRole,
            login,
            logout,
            register,
        }}>
        {props.children}
    </AuthContext.Provider>
}
