import {ReactNode} from "react";
import Box from "@mui/joy/Box";
import {SxProps} from "@mui/joy/styles/types";

export function CenterDiv(props: { children: ReactNode, sx?: SxProps }) {
    return <Box sx={props.sx} className={"flexElements centerElements"}>
        {props.children}
    </Box>
}

export function SpaceBetweenDiv(props: { children: ReactNode, sx?: SxProps }) {
    return <Box sx={props.sx} className={"flexElements spaceBetweenElements"}>
        {props.children}
    </Box>
}

export function FlexDiv(props: { children: ReactNode, sx?: SxProps }) {
    return <Box sx={props.sx} className={"flexElements"}>
        {props.children}
    </Box>
}


export function AlignRightDiv(props: { children: ReactNode, sx?: SxProps }) {
    return <SpaceBetweenDiv sx={props.sx}>
        <div></div>
        <div>{props.children}</div>
    </SpaceBetweenDiv>
}
