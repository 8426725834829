import {useGetCollection} from "../../../../hooks/useHttp";
import {UserEventRegistration} from "../../../../types/ClubEvent";
import Table from "@mui/joy/Table";
import TTh from "../../../layout/joy/TTh";
import useClubEvent from "../../../../hooks/contexts/ClubEventContext";
import LoadingPage from "../../../../pages/LoadingPage";
import Error500 from "../../../../pages/errors/Error500";
import IosSwitch from "../../../form/IosSwitch";
import {useState} from "react";
import useNotifications from "../../../../hooks/contexts/NotificationContext";
import useTranslation from "../../../../hooks/contexts/TranslationContext";
import {putObject} from "../../../../functions/objectService";

export default function SetParticipantsAsAttendedTab() {
    const {clubEvent} = useClubEvent()
    const {
        data: registrations,
        firstLoading,
        error,
        refresh
    } = useGetCollection<UserEventRegistration>(`Organisations/Events/Get/${clubEvent?.id}/Tickets`)

    if (firstLoading)
        return <LoadingPage/>
    if (error)
        return <Error500/>

    return <Table>
        <thead>
        <tr>
            <TTh value={"#"}/>
            <TTh value={"Name"}/>
            <TTh value={"Attended event?"}/>
        </tr>
        </thead>
        <tbody>
        {registrations
            .sort((a, b) => a.user.fullName?.localeCompare(b.user.fullName))
            .map((r, i) => <RegistrationRow key={i} count={i + 1} reg={r} refresh={refresh}/>)}
        </tbody>
    </Table>
}

function RegistrationRow(props: { count: number, reg: UserEventRegistration, refresh: VoidFunction }) {
    const [checked, setChecked] = useState(props.reg.participated)
    const {success, error} = useNotifications()
    const {t} = useTranslation()

    async function handleToggle(val: boolean) {
        try {
            setChecked(val)
            await putObject(`/Organisations/Events/Registration/${props.reg.ticket.id}/Participated`, {
                Value: val
            })
            success(t(`Successfully modified status of ${props.reg.user.fullName}`))
        } catch (e: any) {
            setChecked(!val)
            error(e)
        } finally {
            props.refresh()
        }
    }

    return <tr>
        <td>{props.count}</td>
        <td>{props.reg.user.fullName}</td>
        <td>
            <IosSwitch checked={checked} setChecked={handleToggle}/>
        </td>
    </tr>
}
