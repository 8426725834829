import * as React from 'react';
import {ReactNode} from 'react';
import {CssVarsProvider, useColorScheme} from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';

// Icons import
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import MenuIcon from '@mui/icons-material/Menu';

// custom
import Navigation from './organisationPageLayout/Navigation';
import useOrganisation from "../../hooks/contexts/OrganisationContext";
import {Tooltip} from "@mui/joy";
import SwitchOrgsBtn from "./organisationPageLayout/Buttons/SwitchOrgsBtn";
import {Header, Main, Root, SideDrawer, SideNav} from "./organisationPageLayout/Layout";
import useTranslation from "../../hooks/contexts/TranslationContext";
import SwitchLanguageBtn from "./organisationPageLayout/Buttons/SwitchLanguageBtn";
import LogoutBtn from "./organisationPageLayout/Buttons/LogoutBtn";
import OrgSettingsBtn from "./organisationPageLayout/Buttons/OrgSettingsBtn";

function ColorSchemeToggle() {
    const {mode, setMode} = useColorScheme();
    const [mounted, setMounted] = React.useState(false);
    const {t} = useTranslation()

    React.useEffect(() => {
        setMounted(true);
    }, []);
    if (!mounted) {
        return <IconButton size="sm" variant="soft" color="neutral"/>;
    }
    return <Tooltip title={t(`Go to ${mode === 'light' ? "dark" : "light"} mode`)}>
        <IconButton
            id="toggle-mode"
            size="sm"
            variant="soft"
            color="neutral"
            onClick={() => {
                if (mode === 'light') {
                    setMode('dark');
                } else {
                    setMode('light');
                }
            }}
        >
            {mode === 'light' ? <DarkModeRoundedIcon/> : <LightModeRoundedIcon/>}
        </IconButton>
    </Tooltip>
}

export default function OrganisationPageLayout(props: { children: ReactNode }) {
    const {organisation, orgNavigate} = useOrganisation()
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    return (
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline/>
            {drawerOpen && (
                <SideDrawer onClose={() => setDrawerOpen(false)}>
                    <Navigation/>
                </SideDrawer>
            )}
            <Root
                sx={{
                    gridTemplateColumns: {
                        xs: '1fr',
                        sm: 'minmax(64px, 200px) minmax(450px, 1fr)',
                        md: 'minmax(160px, 300px) minmax(600px, 1fr)',
                    },
                    ...(drawerOpen && {
                        height: '100vh',
                        overflow: 'hidden',
                    }),
                }}
            >
                <Header>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: 1.5,
                        }}
                    >
                        <IconButton
                            variant="outlined"
                            size="sm"
                            onClick={() => setDrawerOpen(true)}
                            sx={{display: {sm: 'none'}}}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <IconButton
                            size="sm"
                            variant="plain"
                            sx={{display: {xs: 'none', sm: 'inline-flex'}}}
                            onClick={() => orgNavigate("")}
                        >
                            <img alt={"logo"} src={organisation.logo}
                                 style={{maxHeight: "25px", maxWidth: "25px"}}/>
                            <Typography component="h1" fontWeight="xl" sx={{mx: 1}}>
                                {organisation.name}
                            </Typography>
                        </IconButton>
                        <OrgSettingsBtn/>
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'row', gap: 1.5}}>
                        <SwitchLanguageBtn/>
                        <SwitchOrgsBtn/>
                        <ColorSchemeToggle/>
                        <LogoutBtn/>
                    </Box>
                </Header>
                <SideNav>
                    <Navigation/>
                </SideNav>
                <Main sx={{mr: 3}}>
                    {props.children}
                </Main>
            </Root>
        </CssVarsProvider>
    );
}
