import React, {ReactNode} from "react";
import {ClubEventPhase, EventModifyPhase} from "../../../types/ClubEvent";
import useTranslation from "../../../hooks/contexts/TranslationContext"
import Stepper from '@mui/joy/Stepper';
import Step, {stepClasses, StepProps} from '@mui/joy/Step';
import StepIndicator, {stepIndicatorClasses} from '@mui/joy/StepIndicator';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupIcon from '@mui/icons-material/Group';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import Typography from "@mui/joy/Typography";
import {AlignRightDiv, SpaceBetweenDiv} from "../../../components/layout/FlexDiv";
import {Card, Container, Tooltip} from "@mui/joy";
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import useOrganisation from "../../../hooks/contexts/OrganisationContext";
import IconButton from "@mui/joy/IconButton";
import Box from "@mui/joy/Box";
import GeneralInfoTab from "../../../components/organisation/event/modify/GeneralInfoTab";
import SettingsTab from "../../../components/organisation/event/modify/SettingsTab";
import RegistrationsTab from "../../../components/organisation/event/modify/RegistrationsTab";
import AssignTicketsTab from "../../../components/organisation/event/modify/AssignTicketsTab";
import useClubEvent from "../../../hooks/contexts/ClubEventContext";
import CheckIcon from '@mui/icons-material/Check';
import Button from "@mui/joy/Button";
import LaunchEventTab from "../../../components/organisation/event/modify/LaunchEventTab";
import SetParticipantsAsAttendedTab from "../../../components/organisation/event/modify/SetParticipantsAsAttendedTab";

const hideSaveButtonForPhases = [
    EventModifyPhase.LaunchEvent,
    EventModifyPhase.ManageRegistrations,
    EventModifyPhase.LinkTicketsToRegistrations,
    EventModifyPhase.SetParticipantsAsAttendedAndMarkAsFinished
]

export default function ModifyEvent() {
    const {clubEvent, saveChanges, modifyPhase: phase, setModifyPhase: setPhase} = useClubEvent()
    const {t} = useTranslation()
    const {organisation, orgNavigate} = useOrganisation()

    const isLastStep = phase === EventModifyPhase.SetParticipantsAsAttendedAndMarkAsFinished
        || phase === EventModifyPhase.LaunchEvent
    let lastStepTitle = t(organisation.settings.verifyWhoParticipated
        ? "Mark participants as (un)attended"
        : "Mark as finished")
    if (clubEvent?.status === ClubEventPhase.Draft)
        lastStepTitle = t("Launch event")
    const lastStepIcon = clubEvent?.status === ClubEventPhase.SetupCompleted
        ? <CheckIcon/>
        : <RocketLaunchIcon/>
    const lastStepPhase = clubEvent?.status === ClubEventPhase.SetupCompleted
        ? EventModifyPhase.SetParticipantsAsAttendedAndMarkAsFinished
        : EventModifyPhase.LaunchEvent

    const hideSaveButton = hideSaveButtonForPhases.indexOf(phase) < 0

    return <Container>
        <SpaceBetweenDiv>
            <Tooltip title={t("Go back to event")}>
                <IconButton title={"Go back"} onClick={() => orgNavigate(`/events/${clubEvent?.id}`)}>
                    <KeyboardBackspaceIcon/>
                </IconButton>
            </Tooltip>
            <Typography level={"h4"} sx={{mb: 1}}>
                <>
                    {t("Manage event")}
                    {clubEvent?.title ? `: ${clubEvent?.expandedTitle}` : t('Set event name')}
                </>
            </Typography>
            <div></div>
        </SpaceBetweenDiv>
        <Stepper
            size="lg"
            sx={{
                width: '100%',
                '--StepIndicator-size': '3rem',
                '--Step-connectorInset': '0px',
                [`& .${stepIndicatorClasses.root}`]: {
                    borderWidth: 4,
                },
                [`& .${stepClasses.root}::after`]: {
                    height: 4,
                },
                [`& .${stepClasses.completed}`]: {
                    [`& .${stepIndicatorClasses.root}`]: {
                        borderColor: 'primary.300',
                        color: 'primary.300',
                    },
                    '&::after': {
                        bgcolor: 'primary.300',
                    },
                },
                [`& .${stepClasses.active}`]: {
                    [`& .${stepIndicatorClasses.root}`]: {
                        borderColor: 'currentColor',
                    },
                },
                [`& .${stepClasses.disabled} *`]: {
                    color: 'neutral.outlinedDisabledColor',
                },
            }}
        >
            <StepIconWithToolTip goToTab={setPhase}
                                 phase={EventModifyPhase.DisplayInfo}
                                 currentPhase={phase}
                                 title={t("Display information")}>
                <AutoAwesomeIcon/>
            </StepIconWithToolTip>
            <StepIconWithToolTip goToTab={setPhase}
                                 phase={EventModifyPhase.Settings}
                                 currentPhase={phase}
                                 title={t("Settings")}>
                <SettingsIcon/>
            </StepIconWithToolTip>
            {clubEvent?.status === ClubEventPhase.SetupCompleted
                && <StepIconWithToolTip goToTab={setPhase}
                                        phase={EventModifyPhase.ManageRegistrations}
                                        currentPhase={phase}
                                        title={t("Registrations")}>
                    <GroupIcon/>
                </StepIconWithToolTip>}
            {clubEvent?.status === ClubEventPhase.SetupCompleted
                && <StepIconWithToolTip goToTab={setPhase}
                                        phase={EventModifyPhase.LinkTicketsToRegistrations}
                                        currentPhase={phase}
                                        title={t("Assign tickets to participants")}>
                    <LocalActivityIcon/>
                </StepIconWithToolTip>}
            {<Step
                orientation="vertical"
                onClick={() => setPhase(lastStepPhase)}
                disabled={!isLastStep}
                active={isLastStep}
                indicator={
                    <Tooltip title={lastStepTitle}>
                        <StepIndicator
                            sx={{cursor: "pointer"}}
                            variant={isLastStep
                                ? "solid"
                                : "outlined"}
                            color={isLastStep
                                ? "primary"
                                : "neutral"}>
                            {lastStepIcon}
                        </StepIndicator>
                    </Tooltip>
                }
            >
                <TabTitle title={lastStepTitle} isActive={isLastStep}/>
            </Step>}
        </Stepper>
        <Box sx={{mt: 2}}>
            <Card>
                {EventModifyPhase.DisplayInfo === phase && <GeneralInfoTab/>}
                {EventModifyPhase.Settings === phase && <SettingsTab/>}
                {EventModifyPhase.LaunchEvent === phase && <LaunchEventTab/>}
                {EventModifyPhase.ManageRegistrations === phase && <RegistrationsTab/>}
                {EventModifyPhase.LinkTicketsToRegistrations === phase && <AssignTicketsTab/>}
                {EventModifyPhase.SetParticipantsAsAttendedAndMarkAsFinished === phase &&
                    <SetParticipantsAsAttendedTab/>}
                <AlignRightDiv>
                    {hideSaveButton && <Button
                        sx={{mx: 1}}
                        color={"success"}
                        onClick={saveChanges}>
                        {t("Save changes")}
                    </Button>}
                </AlignRightDiv>
            </Card>
        </Box>
    </Container>
}

function TabTitle({title, isActive}: { title: string, isActive: boolean }) {
    if (!isActive) return <></>

    return <Typography
        sx={{
            textTransform: 'uppercase',
            fontWeight: 'lg',
            fontSize: '0.75rem',
            textAlign: 'center',
            letterSpacing: '0.5px',
        }}
    >
        {title}
    </Typography>
}

function StepIconWithToolTip(props: {
    children: ReactNode,
    title: string,
    goToTab: (e: EventModifyPhase) => void,
    currentPhase: EventModifyPhase,
    phase: EventModifyPhase
    stepProps?: StepProps
}) {
    const {t} = useTranslation()

    const isActive = props.phase === props.currentPhase

    return <Step
        orientation="vertical"
        disabled={props.phase > props.currentPhase}
        completed={props.phase < props.currentPhase}
        active={isActive}
        {...props.stepProps}
        indicator={<Tooltip title={t(props.title)}>
            <StepIndicator onClick={() => props.goToTab(props.phase)}
                           variant={props.phase === props.currentPhase ? "solid" : "outlined"}
                           color={props.phase > props.currentPhase ? "neutral" : "primary"}
                           sx={{cursor: "pointer"}}>
                {props.children}
            </StepIndicator>
        </Tooltip>
        }
    >
        <TabTitle title={props.title} isActive={isActive}/>
    </Step>
}
