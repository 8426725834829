import useTranslation from "./contexts/TranslationContext";
import {FIRST_COME_FIRST_SERVED_EVENT_EXPLAINED, PRIORITIZE_LOYALTY_EVENT_EXPLAINED} from "../utils/eventInfo";

export default function useTranslatedEventInfo() {
    const {t} = useTranslation()

    return {
        FIRST_COME_FIRST_SERVED_EVENT_EXPLAINED: t(FIRST_COME_FIRST_SERVED_EVENT_EXPLAINED),
        PRIORITIZE_LOYALTY_EVENT_EXPLAINED: t(PRIORITIZE_LOYALTY_EVENT_EXPLAINED)
    }
}