import {useAuth} from "../hooks/contexts/Auth";
import Login from "../pages/anonymous/Login";
import {Route, Routes, useNavigate} from "react-router-dom";
import Home from "../pages/Home";
import Error404 from "../pages/errors/Error404";
import Register from "../pages/anonymous/Register";
import CreateOrganisation from "../pages/organisation/Create";
import {OrganisationRouter} from "./OrganisationRouter";
import ForgotPassword from "../pages/anonymous/ForgotPassword";
import ChangePasswordByToken from "../pages/anonymous/ChangePasswordByToken";
import VerifyAccount from "../pages/anonymous/VerifyAccount";
import {useEffect} from "react";
import AccountVerified from "../pages/anonymous/AccountVerified";

export default function MainRouter() {
    const {isAuthorized} = useAuth()

    return <Routes>
        <Route index element={<Home/>}/>
        <Route path={"/register"} element={isAuthorized() ? <RedirectToIndex/> : <Register/>}/>
        <Route path={"/forgot-password"} element={isAuthorized() ? <RedirectToIndex/> : <ForgotPassword/>}/>
        <Route path={"/verify-account"} element={isAuthorized() ? <RedirectToIndex/> : <VerifyAccount/>}/>
        <Route path={"/account-verified"} element={isAuthorized() ? <RedirectToIndex/> : <AccountVerified/>}/>
        <Route path={"/reset-password-token"} element={isAuthorized() ? <RedirectToIndex/> : <ChangePasswordByToken/>}/>

        {
            isAuthorized() && <>
                <Route path={"/login"} element={<RedirectToIndex/>}/>
                <Route path={"/create"} element={<CreateOrganisation/>}/>
                {/*<Route path={"/account"} element={<ModifyAccount/>}/>*/}
                <Route path={"/:organisationUri/*"} element={<OrganisationRouter/>}/>
                <Route path={"/*"} element={<Error404/>}/>
            </>
        }
        <Route path={"/:organisationUri/*"} element={<Login/>}/>
        <Route path={"*"} element={isAuthorized() ? <Error404/> : <Login/>}/>
    </Routes>
}

function RedirectToIndex() {
    const navigate = useNavigate();
    useEffect(() => {
        navigate("/")
    })
    return <></>
}