import {getRandom} from "./number";

export default function getRandomBackground() {
    return Backgrounds[getRandom(0, Backgrounds.length - 1)]
}

export const Backgrounds: string[] = [
    'https://i.imgur.com/BbwuBNf.jpg', //boca
    'https://i.imgur.com/tJ7FCtc.png', //celtic
    'https://i.imgur.com/LsazLxD.jpg', //dortmund
    'https://i.imgur.com/5h0DvMy.jpg', //blue club
    'https://i.imgur.com/eHC0Z6H.jpg', //bayern
    'https://i.imgur.com/ZBCSO2F.png', //flamengo
]