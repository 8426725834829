import IconButton from "@mui/joy/IconButton";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import * as React from "react";
import {useState} from "react";
import Typography from "@mui/joy/Typography";
import {useAuth} from "../../../../hooks/contexts/Auth";
import SearchIcon from '@mui/icons-material/Search';
import {useNavigate} from "react-router-dom";
import Modal from "../../modal/Modal";
import AddIcon from '@mui/icons-material/Add';
import TTypography from "../../joy/TTypography";
import {Tooltip} from "@mui/joy";
import useTranslation from "../../../../hooks/contexts/TranslationContext";

export default function SwitchOrgsBtn() {
    const {user} = useAuth()
    const [open, setOpen] = useState(false)
    const navigate = useNavigate()
    const {t} = useTranslation()

    if (!user) return <></>

    const boxSize = 120
    return <>
        <Tooltip title={t("Change of organisation")}>
            <IconButton variant={"soft"} onClick={() => setOpen(true)}><GridViewRoundedIcon/></IconButton>
        </Tooltip>
        <Modal open={open} onClose={() => setOpen(false)} title={"Select organisation"} sx={{minWidth: 200}}>
            {user.following.map(org => <IconButton key={org.id}
                                                   variant="soft" sx={{m: 1}}
                                                   onClick={() => {
                                                       setOpen(false)
                                                       navigate(`/${org.uriParam}`)
                                                   }}>
                <div style={{padding: 10, height: boxSize, width: boxSize}}>
                    <img style={{height: 70, padding: 10}} src={org.logo} alt={org.name}/>
                    <Typography>{org.shortName}</Typography>
                </div>
            </IconButton>)}
            <IconButton variant="soft" sx={{m: 1}} onClick={() => navigate("/")}>
                <div style={{padding: 10, height: boxSize, width: boxSize}}>
                    <SearchIcon sx={{fontSize: 70}}/>
                    <TTypography>Search</TTypography>
                </div>
            </IconButton>
            <IconButton variant="soft" sx={{m: 1}} onClick={() => navigate("/create")}>
                <div style={{padding: 10, height: boxSize, width: boxSize}}>
                    <AddIcon sx={{fontSize: 70}}/>
                    <TTypography>Create</TTypography>
                </div>
            </IconButton>
        </Modal>
    </>
}
