import Dropzone, {DropzoneProps, DropzoneRef} from "react-dropzone";
import Box from "@mui/joy/Box";
import * as React from "react";
import useTranslation from "../../hooks/contexts/TranslationContext";

export default function FileDrop(props: DropzoneProps & React.RefAttributes<DropzoneRef>) {
    const {t} = useTranslation()

    return <Dropzone {...props}>
        {({getRootProps, getInputProps}) => (
            <Box sx={{border: "2px dashed grey", px: 2, py: 1, cursor: "pointer"}}>
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>{t("Drag 'n' drop some files here, or click to select files")}</p>
                </div>
            </Box>
        )}
    </Dropzone>
}
