import {ReactNode} from "react";
import useTranslation from "../../../../../hooks/contexts/TranslationContext";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";

export default function ExtraOptionsSection(props: { title: string, children: ReactNode }) {
    const {t} = useTranslation()

    return <Box sx={{my: 2}}>
        <Typography level={"title-sm"}>{t(props.title)}</Typography>
        <div>
            {props.children}
        </div>
    </Box>
}
