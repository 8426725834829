import InputControl from "../../../form/InputControl";
import {useForm} from "react-hook-form";
import useTranslation from "../../../../hooks/contexts/TranslationContext";
import {Grid} from "@mui/joy";
import RichEditor from "../../../form/RichEditor";
import Typography from "@mui/joy/Typography";
import Box from "@mui/joy/Box";
import useClubEvent from "../../../../hooks/contexts/ClubEventContext";
import ClubEvent from "../../../../types/ClubEvent";

export default function GeneralInfoTab() {
    const {clubEvent, updateEvent} = useClubEvent()
    const {t} = useTranslation()
    const {register, getValues} = useForm()

    function updateClubEvent(key: string) {
        updateEvent({...clubEvent as ClubEvent, [key]: (getValues(key) ?? '').trim()})
    }

    return <>
        <Grid container>
            <Grid md={6}>
                <InputControl
                    label={t("Title")}
                    register={register("title", {
                        value: clubEvent?.title,
                        onChange: () => updateClubEvent("title")
                    })}/>
            </Grid>
            <Grid md={6}>
                <InputControl
                    label={t("Meetup address")}
                    register={register("eventMeetupAddress", {
                        value: clubEvent?.eventMeetupAddress,
                        onChange: () => updateClubEvent("eventMeetupAddress")
                    })}/>
            </Grid>
            <Grid md={12}>
                <InputControl
                    label={t("Banner (url)")}
                    register={register("banner", {
                        value: clubEvent?.banner,
                        onChange: () => updateClubEvent("banner")
                    })}/>
            </Grid>
            <Grid md={12}>
                <Typography level={"title-sm"} sx={{m: 1}}>
                    {t("Description")}
                </Typography>
                <Box sx={{mx: 1}}>
                    <RichEditor value={clubEvent?.description ?? ''}
                                onChange={e => updateEvent({...clubEvent as ClubEvent, description: e})}/>
                </Box>
            </Grid>
        </Grid>
    </>
}