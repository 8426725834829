import moment from "moment";

export function dateString(date: Date) {
    const useDate = new Date(date)
    return useDate.toISOString().split('T')[0]
}

export function dateTimeStringWithSeconds(date: Date | undefined) {
    if (date === undefined) return 'N/A'
    const useDate = new Date(date)
    return dateString(date)
        + ' '
        + useDate.toLocaleTimeString('be-BE')
}

export function dateTimeString(date: Date | undefined) {
    if (date === undefined) return ''
    const result = dateTimeStringWithSeconds(date)
    return result.substring(0, result.lastIndexOf(':'))
}

export function compare(a: Date, b: Date) {
    return new Date(a).getTime() - new Date(b).getTime()
}

export function toInputDateFormat(a: Date | undefined) {
    if (a === undefined) a = new Date()
    return moment(a).format('YYYY-MM-D')
}
