import DefaultPageLayout from "../components/layout/DefaultPageLayout";
import Organisation from "../types/Organisation";
import OrganisationBannerCard from "../components/particles/OrganisationBannerCard";
import Input from "@mui/joy/Input";
import {useGetCollection} from "../hooks/useHttp";
import LoadingPage from "./LoadingPage";
import Error500 from "./errors/Error500";
import {useEffect, useState} from "react";
import {filterEntity} from "../utils/string";
import SetPublicPageInfo from "../components/layout/SetPublicPageInfo";
import useTranslation from "../hooks/contexts/TranslationContext";
import useInterval from "../hooks/useInterval";
import {Container, Grid, Tooltip} from "@mui/joy";
import {greyTheme} from "../utils/theme";
import {CssVarsProvider} from "@mui/joy/styles";
import IconButton from "@mui/joy/IconButton";
import {CreateIcon} from "../components/layout/CrudIcons";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../hooks/contexts/Auth";
import Box from "@mui/joy/Box";

export default function Home() {
    return <CssVarsProvider theme={greyTheme}>
        <Box sx={{bgcolor: 'background.surface', height: "100vh"}}>
            <PageContent/>
        </Box>
    </CssVarsProvider>
}

function PageContent() {
    const {
        data: organisations,
        error,
        refresh,
        firstLoading
    } = useGetCollection<Organisation>("/Organisations/Get")
    const [filter, setFilter] = useState("")
    const {user} = useAuth()
    const {t} = useTranslation()
    const navigate = useNavigate()
    useInterval(refresh, error ? 3 : 300)

    useEffect(() => {
        if (window.history.length < 3 && (user?.following ?? []).length === 1)
            navigate(`/${user!.following[0].uriParam}`)
    }, [navigate, user]);

    if (firstLoading) return <LoadingPage/>
    if (error) return <Error500/>

    return <Container sx={{py: 5}}>
        <SetPublicPageInfo/>
        <DefaultPageLayout title={"Find your supporters club!"}>
            <Input
                placeholder={t("Insert a club name...")}
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                endDecorator={<InputCreateOrgButton/>}
                sx={{mb: 1}}
            />
            <OrganisationList orgs={organisations} filter={filter}/>
        </DefaultPageLayout>
    </Container>
}

export function OrganisationList({orgs, filter}: { orgs: Organisation[], filter: string }) {
    return <Grid container spacing={1}>
        {orgs
            .filter(e => filterEntity(e, filter))
            .map((val, index) => <Grid key={index} xs={12} sm={12} md={6} lg={4}>
                <OrganisationBannerCard org={val}/>
            </Grid>)}
    </Grid>
}

function InputCreateOrgButton() {
    const {t} = useTranslation()
    const navigate = useNavigate()

    return <Tooltip
        onClick={() => navigate("/create")}
        title={t("Create your own organisation!")}>
        <IconButton>
            <CreateIcon/>
        </IconButton>
    </Tooltip>
}
