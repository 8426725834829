/* eslint-disable react-hooks/exhaustive-deps */
import Input, {InputProps} from "@mui/joy/Input";
import useTranslation from "../../hooks/contexts/TranslationContext";
import FormLabel from "@mui/joy/FormLabel";
import moment from "moment";
import FormControl from "@mui/joy/FormControl";
import React from "react";

export default function InputDateTimeControl({label, error, inputProps, value, setValue}: {
    label?: string | undefined,
    error?: undefined | boolean,
    inputProps?: InputProps,
    value: Date,
    setValue: React.Dispatch<React.SetStateAction<Date>>
}) {
    const {t} = useTranslation()

    return <>
        {label && <FormControl sx={{my: 0.5}}><FormLabel>{t(label)}</FormLabel></FormControl>}
        <Input defaultValue={moment(value).format("yyyy-MM-DD HH:mm:ss")}
               type={"datetime-local"} {...inputProps}
               error={error}
               onChange={e => {
                   if (moment(e.target.value).isValid() && e.target.value !== "") {
                       setValue(moment(e.target.value).toDate())
                   }
               }}
               sx={{mr: 1}}
        />
    </>
}
