import useMediaQuery from "./useMediaQuery";

const maxWidth = (val: number) => `(max-width:${val}px)`
const minWidth = (val: number) => `(min-width:${val}px)`

const phoneMaxWidth = 600
const monitorMinWidth = 1200
const largeMonitorMinWidth = 2100
export default function useScreen() {
    const Phone = useMediaQuery(maxWidth(phoneMaxWidth))
    const Tablet = useMediaQuery(minWidth(phoneMaxWidth) + ' and ' + maxWidth(monitorMinWidth))
    const Laptop = useMediaQuery(minWidth(monitorMinWidth) + ' and ' + maxWidth(largeMonitorMinWidth))
    const UltraWideMonitor = useMediaQuery(minWidth(largeMonitorMinWidth))

    return {
        Phone,
        Tablet,
        Monitor: Laptop || UltraWideMonitor,
        Laptop,
        UltraWideMonitor,
        PhoneOrTablet: Phone || Tablet
    }
}
