import NotLoggedInLayout from "../../components/layout/NotLoggedInLayout";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Box from "@mui/joy/Box";
import Link from "@mui/joy/Link";
import Button from "@mui/joy/Button";
import {useLocation, useNavigate} from "react-router-dom";
import useShowPassword from "../../hooks/useShowPassword";
import {postObject} from "../../functions/objectService";
import useNotifications from "../../hooks/contexts/NotificationContext";
import useTranslation from "../../hooks/contexts/TranslationContext";
import {useState} from "react";
import LoadingButton from "../../components/layout/form/LoadingButton";

export default function ChangePasswordByToken() {
    const navigate = useNavigate()
    const {type, decorator} = useShowPassword()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const {error} = useNotifications()
    const {t} = useTranslation()
    const [isLoading, setIsLoading] = useState(false)

    async function handleSubmit(event: any) {
        try {
            setIsLoading(true)
            event.preventDefault();
            const formElements = event.currentTarget.elements;
            await postObject("/auth/password/token", {
                email: formElements.email.value,
                token: formElements.token.value,
                password: formElements.password.value,
            })
            navigate("/account-verified")
        } catch (e: any) {
            error(e.response.data.reason)
        } finally {
            setIsLoading(false)
        }
    }

    return <NotLoggedInLayout title={t("Change password by token")}
                              subTitle={t("Check your inbox to retrieve your token!")}>
        <form
            onSubmit={handleSubmit}
        >
            <FormControl required>
                <FormLabel>{t("Token")}</FormLabel>
                <Input defaultValue={queryParams.get('token') as string | undefined} autoComplete={"off"} name="token"/>
            </FormControl>
            <FormControl required>
                <FormLabel>{t("Email")}</FormLabel>
                <Input defaultValue={queryParams.get('email') as string | undefined} type="email" name="email"/>
            </FormControl>
            <FormControl required>
                <FormLabel>{t("Password")}</FormLabel>
                <Input autoComplete={"off"} type={type} endDecorator={decorator} name="password"/>
            </FormControl>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Link fontSize="sm" onClick={() => navigate("/login")} fontWeight="lg" color={"neutral"}>
                    {t("Login")}
                </Link>
                <Link fontSize="sm" onClick={() => navigate("/register")} fontWeight="lg" color={"neutral"}>
                    {t("Register")}
                </Link>
            </Box>
            {isLoading
                ? <LoadingButton/>
                : <Button type="submit" fullWidth color={"neutral"}>
                    {t("Change password")}
                </Button>}
        </form>
    </NotLoggedInLayout>
}
