import {useParams} from "react-router-dom";
import Error404 from "../errors/Error404";
import {CenterDiv} from "../../components/layout/FlexDiv";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import useTranslation from "../../hooks/contexts/TranslationContext";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

export default function Payment() {
    const {state, description} = useParams<{ state: string, description: string }>()
    const {t} = useTranslation()

    if (state === undefined)
        return <Error404/>

    const success = state === "success"
    const stateString = success ? "succeeded" : "failed"

    const iconSx = {fontSize: 80}

    return <CenterDiv>
        <Box sx={{m: 3, textAlign: "center"}}>
            {success
                ? <CheckCircleIcon sx={{...iconSx, color: "green"}}/>
                : <CancelIcon sx={{...iconSx, color: "red"}}/>}
            <Typography level={"title-lg"} sx={{mb: 2}}>
                {`${t("Payment")} ${stateString}`}
            </Typography>
            {description && <Typography level={"body-sm"}>
                {t(description ?? '')}
            </Typography>}
        </Box>
    </CenterDiv>
}
