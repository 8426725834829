import {useForm} from "react-hook-form";
import ClubEvent, {ClubEventType, EmptyClubEvent} from "../../../types/ClubEvent";
import useOrganisation from "../../../hooks/contexts/OrganisationContext";
import InputControl from "../../form/InputControl";
import Typography from "@mui/joy/Typography";
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";
import AddIcon from '@mui/icons-material/Add';
import * as React from "react";
import {useState} from "react";
import Button from "@mui/joy/Button";
import LoadingButton from "../../layout/form/LoadingButton";
import useNotifications from "../../../hooks/contexts/NotificationContext";
import {postEntityWithResponse} from "../../../functions/objectService";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListItemContent from "@mui/joy/ListItemContent";
import ListItemButton from "@mui/joy/ListItemButton";
import Modal from "../../layout/modal/Modal";
import {CenterDiv} from "../../layout/FlexDiv";
import SelectEventType from "./SelectEventType";
import ListItem from "@mui/joy/ListItem/ListItem";
import EventIcon from '@mui/icons-material/Event';
import useTranslation from "../../../hooks/contexts/TranslationContext";

export default function CreateEventFormButton() {
    const [open, setOpen] = useState(false)
    const {organisation, orgNavigate} = useOrganisation()
    const {register, getValues} = useForm<ClubEvent>()
    const {error, success} = useNotifications()
    const [selectedType, setSelectedType] = useState<ClubEventType | null>(null)
    const [submitted, setSubmitted] = useState(false)
    const {t} = useTranslation()

    const validate = () => {
        const errors = []
        if (getValues("title").length < 2) errors.push("Event title must have at least 2 characters")
        if (getValues("startEvent") === null) errors.push("Start of event is a mandatory field")
        if (getValues("endEvent") === null) errors.push("End of event is a mandatory field")
        if (selectedType === null) errors.push("You must select a type")
        return errors.map(e => t(e))
    }

    async function handleSubmit() {
        try {
            setSubmitted(true)
            if (validate().length > 0) {
                validate().forEach(errMsg => error(errMsg + "!"))
                return;
            }
            const response = await postEntityWithResponse<ClubEvent, string>(`/Organisations/${organisation.id}/Events/Create`, {
                ...EmptyClubEvent(),
                eventType: selectedType as ClubEventType,
                title: getValues("title")
            })
            orgNavigate(`/events/${response.data}/manage`)
            success(t(`Successfully created event`))
            setOpen(false)
        } catch (errMsg: any) {
            error(errMsg)
        } finally {
            setSubmitted(false)
        }
    }

    return <ListItem>
        <ListItemButton onClick={() => setOpen(true)}>
            <ListItemDecorator>
                <EventIcon/>
            </ListItemDecorator>
            <ListItemContent>{t("Create event")}</ListItemContent>
        </ListItemButton>
        <Modal open={open} onClose={() => setOpen(false)} title={"Create event"}
               sx={{minWidth: 300, maxWidth: 500}}>
            <InputControl label={"Title"}
                          placeholder={"Title"}
                          register={register("title")}/>
            <Box sx={{display: "flex", justifyContent: "space-between", mt: 2}}>
                <Typography level={"title-lg"}>{t("Select event type")}</Typography>
                <IconButton onClick={() => {
                    orgNavigate("/events/types")
                    setOpen(false)
                }}>
                    <AddIcon/>
                </IconButton>
            </Box>

            <SelectEventType setType={setSelectedType} selected={selectedType}/>

            <CenterDiv sx={{m: 1}}>
                {submitted ? <LoadingButton/> : <Button color={"success"} onClick={handleSubmit}>
                    {t("Create event")}
                </Button>}
            </CenterDiv>
        </Modal>
    </ListItem>
}
