import {useGetCollection} from "../../../hooks/useHttp";
import Error500 from "../../errors/Error500";
import LoadingPage from "../../LoadingPage";
import {MembershipsWithPoints} from "../../../types/Membership";
import useOrganisation from "../../../hooks/contexts/OrganisationContext";
import useTranslation from "../../../hooks/contexts/TranslationContext";
import Table from "@mui/joy/Table";
import {dateString} from "../../../utils/date";
import Input from "@mui/joy/Input";
import {useState} from "react";
import IconButton from "@mui/joy/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PageTitle from "../../../components/layout/joy/PageTitle";

export default function List() {
    const {ORG_URI, orgNavigate} = useOrganisation()
    const {data, firstLoading, error} = useGetCollection<MembershipsWithPoints>(`${ORG_URI}/Members`)
    const {t} = useTranslation()
    const [filter, setFilter] = useState("")

    if (firstLoading) return <LoadingPage title={"Loading members"}/>
    if (error) return <Error500/>

    const filtered = data
        .filter(e =>
            e.userMembership.user.fullName.toLowerCase().includes(filter.toLowerCase())
            || e.userMembership.membership.title.toLowerCase().includes(filter.toLowerCase())
        )

    return <>
        <PageTitle>Members of organisation</PageTitle>
        <Input
            placeholder={"Filter"}
            sx={{mb: 1}}
            value={filter}
            onChange={e => setFilter(e.target.value ?? "")}/>
        <Table aria-label="basic table">
            <thead>
            <tr>
                <th>{t("#")}</th>
                <th>{t("Name")}</th>
                <th>{t("Membership")}</th>
                <th>{t("Points")}</th>
                <th>{t("Membership expires")}</th>
                <th>{t("Options")}</th>
            </tr>
            </thead>
            <tbody>
            {filtered
                .sort((a, b) => b.points - a.points)
                .map((row, index) => <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{row.userMembership.user.fullName}</td>
                    <td>{row.userMembership.membership.title}</td>
                    <td>{row.points}</td>
                    <td>{dateString(row.userMembership.membership.expiry)}</td>
                    <td>
                        <IconButton color={"neutral"}
                                    onClick={() => orgNavigate(`/members/${row.userMembership.user.id}`)}>
                            <VisibilityIcon/>
                        </IconButton>
                    </td>
                </tr>)}
            </tbody>
        </Table>
    </>
}
