import {useGetCollection} from "../../../hooks/useHttp";
import {UserEventRegistration} from "../../../types/ClubEvent";
import LoadingPage from "../../LoadingPage";
import Error500 from "../../errors/Error500";
import {compare} from "../../../utils/date";
import {Alert, Grid} from "@mui/joy";
import useTranslation from "../../../hooks/contexts/TranslationContext";
import {FlexDiv, SpaceBetweenDiv} from "../../../components/layout/FlexDiv";
import PageTitle from "../../../components/layout/joy/PageTitle";
import IosSwitchControl from "../../../components/form/IosSwitchControl";
import useOrganisation from "../../../hooks/contexts/OrganisationContext";
import {useLocation} from "react-router-dom";
import NoResultsFound from "../../../components/layout/NoResultsFound";
import TicketCard from "../../../components/organisation/account/TicketCard";
import useBrowserSetting from "../../../hooks/useBrowserSetting";
import Box from "@mui/joy/Box";
import useNotifications from "../../../hooks/contexts/NotificationContext";
import {useEffect} from "react";

export default function Tickets() {
    const {value: showArchivedTickets, toggle: toggleShowArchivedTickets} = useBrowserSetting("showArchiveTickets")
    const {value: currentOrgOnly, toggle: toggleCurrentOrgOnly} = useBrowserSetting("currentOrgOnly")
    const {
        data,
        firstLoading,
        error
    } = useGetCollection<UserEventRegistration>(`/account/tickets?showArchived=${showArchivedTickets}`)
    const {organisation} = useOrganisation()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const {t} = useTranslation()
    const {success: sendSuccess, error: sendError} = useNotifications()

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        const errorMsg = queryParams.get('error')
        const paid = queryParams.get('paid')
        const ticketName = queryParams.get('ticketName')
        if (paid === "1" && ticketName)
            sendSuccess(`Received payment for ${ticketName}`)
        if (errorMsg)
            sendError(errorMsg)
    }, [sendSuccess, sendError, location.search])

    if (firstLoading) return <LoadingPage title={"Loading tickets"}/>
    if (error) return <Error500/>

    const filtered = data
        .filter(r => r?.ticket?.id !== undefined)
        .filter(r => currentOrgOnlyFilter(currentOrgOnly, organisation.id, r))

    const isPaid = queryParams.get('paid') === '1'
    const ticketName = queryParams.get("ticketName")

    return <>
        <SpaceBetweenDiv>
            <div>
                <PageTitle>Tickets</PageTitle>
            </div>
            <div>
                <FlexDiv>
                    <Box sx={{ml: 2}}>
                        <IosSwitchControl
                            label={"Current organisation only"}
                            checked={currentOrgOnly}
                            setChecked={() => toggleCurrentOrgOnly()}/>
                    </Box>
                    <Box sx={{ml: 2}}>
                        <IosSwitchControl
                            label={"Show archived tickets"}
                            checked={showArchivedTickets}
                            setChecked={() => toggleShowArchivedTickets()}/>
                    </Box>
                </FlexDiv>
            </div>
        </SpaceBetweenDiv>
        {ticketName && <Alert sx={{mb: 1}} variant={"soft"} color={isPaid ? "success" : "warning"}>
            <strong>{t(isPaid ? "Success!" : "Warning!")}</strong>
            {t(isPaid
                ? "We have received your payment for [TICKET_NAME]!"
                : "We have not yet received your payment, we will keep on checking the status of this transaction for up to 30 minutes!")
                .replace("[TICKET_NAME]", ticketName)}
        </Alert>}
        <div style={{width: "100%"}}>
            <Grid
                container
                spacing={{xs: 2, md: 3}}
                sx={{flexGrow: 1}}
            >
                {filtered
                    .sort((a, b) => compare(a.ticket.modifiedOn, b.ticket.modifiedOn))
                    .reverse()
                    .map((r, index) => (
                        <Grid xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
                            <TicketCard reg={r}/>
                        </Grid>
                    ))}
            </Grid>
        </div>
        {filtered.length === 0 && <NoResultsFound msg={"No tickets found!"}/>}
    </>
}

function currentOrgOnlyFilter(currentOrgOnly: boolean, orgId: string, reg: UserEventRegistration) {
    return !currentOrgOnly || reg.orgId === orgId
}

