import {ReactNode, useState} from "react";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import IconButton from "@mui/joy/IconButton";
import {Tooltip} from "@mui/joy";
import useTranslation from "./contexts/TranslationContext";

export default function useShowPassword(): IShowPassword {
    const {t} = useTranslation()
    const [show, setShow] = useState(false)

    return {
        decorator: <Tooltip title={t(show ? "Hide password" : "Show password")}>
            <IconButton onClick={() => setShow(!show)}>
                {show ? <VisibilityOff/> : <Visibility/>}
            </IconButton>
        </Tooltip>,
        type: show ? "text" : "password"
    }
}

interface IShowPassword {
    type: "text" | "password",
    decorator: ReactNode
}