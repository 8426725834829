export function getRandom(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function bytesForHuman(bytes: number, decimals = 2) {
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']

    let i = 0

    for (i; bytes > 1024; i++) {
        bytes /= 1024;
    }

    return parseFloat(bytes.toFixed(decimals)) + ' ' + units[i]
}
