import FormLabel from "@mui/joy/FormLabel";
import Input, {InputProps} from "@mui/joy/Input";
import FormControl from "@mui/joy/FormControl";
import {UseFormRegisterReturn} from "react-hook-form";
import useTranslation from "../../hooks/contexts/TranslationContext";

export default function InputControl({label, placeholder, register, error, inputProps}: {
    label?: string | undefined,
    placeholder?: string | undefined,
    register?: UseFormRegisterReturn<any>,
    error?: undefined | boolean,
    inputProps?: InputProps
}) {
    const {t} = useTranslation()

    return <FormControl sx={{m: 1}}>
        {label && <FormLabel>{t(label)}</FormLabel>}
        <Input {...inputProps} error={error} {...register} placeholder={placeholder}/>
    </FormControl>
}
