import {AlignRightDiv, CenterDiv} from "../../layout/FlexDiv";
import useOrganisation from "../../../hooks/contexts/OrganisationContext";
import {useGetCollection} from "../../../hooks/useHttp";
import {CloudFile, filterCloudFile} from "../../../types/CloudFile";
import LoadingPage from "../../../pages/LoadingPage";
import Typography from "@mui/joy/Typography";
import Table from "@mui/joy/Table";
import {bytesForHuman} from "../../../utils/number";
import * as React from "react";
import {useState} from "react";
import Input from "@mui/joy/Input";
import useTranslation from "../../../hooks/contexts/TranslationContext";
import IconButton from "@mui/joy/IconButton";
import {DeleteIcon} from "../../layout/CrudIcons";
import {Download} from "@mui/icons-material";
import axios from "axios";
import {deleteObject} from "../../../functions/objectService";
import useNotifications from "../../../hooks/contexts/NotificationContext";

export default function OrganisationCloud() {
    const {ORG_URI} = useOrganisation()
    const {hasLoaded, data: files, noData, refresh} = useGetCollection<CloudFile>(`${ORG_URI}/Cloud/Files`)
    const [filter, setFilter] = useState('')
    const {t} = useTranslation()

    if (!hasLoaded) return <LoadingPage/>
    const usedSpace = files.map(e => e.fileSize).reduce((partialSum, a) => partialSum + a, 0)
    const maxSpace = 3000000000
    const percentageUsed = usedSpace / maxSpace

    return <>
        <Input sx={{mb: 2}} placeholder={t("Search")} onChange={(e) => setFilter(`${e.target.value}`)} value={filter}/>
        {noData
            ? <NoFiles/>
            : <Files
                refresh={refresh}
                files={files.filter(e => filterCloudFile(e, filter))}/>}
        <AlignRightDiv>
            <Typography
                level={"title-sm"}>{`${t("Used storage space")}: ${Math.trunc(percentageUsed)} % (${bytesForHuman(usedSpace)} / ${bytesForHuman(maxSpace)})`}</Typography>
        </AlignRightDiv>
    </>
}

function Files(props: { files: CloudFile[], refresh: VoidFunction }) {
    const {success, error} = useNotifications()
    const {t} = useTranslation()
    const {organisation} = useOrganisation()

    async function deleteFile(fileId: string) {
        try {
            await deleteObject(`/Organisations/${organisation.id}/Cloud/${fileId}`)
            success(t("Successfully deleted file"))
            props.refresh()
        } catch (e: any) {
            error(e)
        }
    }

    let i = 1
    return <>
        <Table aria-label="basic table">
            <thead>
            <tr>
                <th>#</th>
                <th>Name</th>
                <th>Type</th>
                <th>Size</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {props.files.map(f => <tr key={f.id}>
                <td>{i++}</td>
                <td>{f.name}</td>
                <td>{f.type}</td>
                <td>{bytesForHuman(f.fileSize)}</td>
                <td>
                    <IconButton
                        onClick={() => window.open(`${axios.defaults.baseURL}/Organisations/${organisation.id}/Cloud/${f.id}`)}
                        color={"success"}>
                        <Download/>
                    </IconButton>
                    <IconButton
                        onClick={() => deleteFile(f.id)}
                        color={"danger"}>
                        <DeleteIcon/>
                    </IconButton>
                </td>
            </tr>)}
            </tbody>
        </Table>
    </>
}

function NoFiles() {
    return <CenterDiv>
        <Typography sx={{my: 5}} level={"title-lg"}>
            You can store files here!
        </Typography>
    </CenterDiv>
}
