export interface ApplicationUser {
    firstName: string
    lastName: string
    fullName: string
    registerDate: Date
    points: any[]
    memberships: any[]
    registrations: any[]
    id: string
}

export interface MemberInfo {
    id: string
    firstName: string
    lastName: string
    fullName: string
    registerDate: Date
    points: number
    nationalInsuranceNumber: string | null
}

export function getInitials(user: ApplicationUser | MemberInfo) {
    try {
        if (user.fullName.length === 0)
            return "NA"
        if (!user.fullName.includes(" "))
            return user.fullName[0].toUpperCase()

        return user.fullName[0].toUpperCase()
            + user.fullName[user.fullName.lastIndexOf(" ") + 1].toUpperCase()
    } catch {
        return "NA"
    }
}
