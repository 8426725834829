import ManageUserDataAccordion from "./ManageUserDataAccordion";
import TimelineIcon from "@mui/icons-material/Timeline";
import {AccordionGroup} from "@mui/joy";
import {useState} from "react";
import PointsHistory from "./accordions/PointsHistory";
import EventSeatIcon from '@mui/icons-material/EventSeat';

export default function ManageUserData() {
    const [activeTitle, setActiveTitle] = useState<string | null>(null)

    return <AccordionGroup
        variant="outlined"
        transition="0.2s"
        sx={{p: 2, borderRadius: 10}}>
        <ManageUserDataAccordion
            icon={<TimelineIcon/>}
            title={"Points history"}
            desc={"History of where the user has gained their loyalty points."}
            color={"success"}
            active={activeTitle}
            setActive={setActiveTitle}
        >
            <PointsHistory/>
        </ManageUserDataAccordion>
        <ManageUserDataAccordion
            icon={<EventSeatIcon/>}
            title={"Event history"}
            desc={"History of events this user has registered for"}
            color={"warning"}
            active={activeTitle}
            setActive={setActiveTitle}
        >
            Incoming
        </ManageUserDataAccordion>
    </AccordionGroup>
}
