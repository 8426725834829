import {Grid, Slider} from "@mui/joy";
import InputControl from "../../components/form/InputControl";
import Button from "@mui/joy/Button";
import IosSwitchControl from "../../components/form/IosSwitchControl";
import {useState} from "react";
import Organisation, {emptyOrganisation} from "../../types/Organisation";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import {CssVarsProvider} from "@mui/joy/styles";
import {getSliderTheme} from "../../utils/theme";
import {postObject} from "../../functions/objectService";
import {useNavigate} from "react-router-dom";
import FormCard from "../../components/layout/form/FormCard";
import FormCardHeader from "../../components/layout/form/FormCardHeader";
import FormActions from "../../components/layout/form/FormActions";
import {useForm} from "react-hook-form";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SaveIcon from '@mui/icons-material/Save';
import SetPublicPageInfo from "../../components/layout/SetPublicPageInfo";
import useTranslation from "../../hooks/contexts/TranslationContext";

export default function Create() {
    const [organisation, setOrganisation] = useState(emptyOrganisation())
    const {register, getValues} = useForm()
    const [color, setColor] = useState(180)
    const navigate = useNavigate()
    const {t} = useTranslation()

    async function submit() {
        const response = await postObject<Organisation>("/organisations/create", {
            ...organisation,
            name: getValues("name"),
            shortName: getValues("shortName"),
            logo: getValues("logo"),
            banner: getValues("banner"),
            uriParam: getValues("uriParam"),
            settings: {
                ...organisation.settings,
                hslDefaultColor: color,
                defaultEventMeetupAddress: getValues("defaultEventMeetupAddress")
            }
        })
        navigate(`/${response.data.uriParam}/Settings`)
    }

    return <>
        <SetPublicPageInfo/>
        <FormCard>
            <FormCardHeader
                title={"Create organisation"}
                subtitle={"You have the ability to change all information in your organisation settings."}
            />
            <Grid container>
                <Grid xs={6}>
                    <InputControl label="Full name" placeholder="The flying pigeons" register={register("name")}/>
                    <InputControl label="Short name" placeholder="TFP" register={register("shortName")}/>
                </Grid>
                <Grid xs={6}>
                    <InputControl label="Path for website" placeholder="flying_pigeons"
                                  register={register("uriParam")}/>
                    <InputControl label="URL to Logo" placeholder="https://example.com/hjefiuhz.png"
                                  register={register("logo")}/>
                </Grid>
                <Grid xs={12} sx={{mt: 1}}>
                    <InputControl label="Default meetup address for events"
                                  placeholder="Nationalestraat 105, 2000 Antwerpen"
                                  register={register("defaultEventMeetupAddress")}/>

                    <IosSwitchControl
                        label="Only grant loyalty points to people who have actually participated"
                        subLabel="This requires extra checks from organisation administrators"
                        checked={organisation.settings.verifyWhoParticipated}
                        setChecked={(val: boolean) => {
                            setOrganisation({
                                ...organisation,
                                settings: {...organisation.settings, verifyWhoParticipated: val}
                            })
                        }}/>
                    {/*<IosSwitchControl
                        label="Allow members to view events that have already passed"
                        checked={organisation.settings.membersCanSeeArchivedEvents}
                        setChecked={(val: boolean) => {
                            setOrganisation({
                                ...organisation,
                                settings: {...organisation.settings, membersCanSeeArchivedEvents: val}
                            })
                        }}/>*/}
                    <IosSwitchControl
                        label="Use loyalty points for who will be selected/allowed to participate in an event"
                        subLabel="Disabling this will use the first come first served principe for events"
                        checked={organisation.settings.defaultUseFirstComeFirstServedPrinciple}
                        setChecked={(val: boolean) => {
                            setOrganisation({
                                ...organisation,
                                settings: {...organisation.settings, defaultUseFirstComeFirstServedPrinciple: val}
                            })
                        }}/>
                    {/*<IosSwitchControl
                        label="New people must be manually confirmed before they can pay for their memberships"
                        subLabel="Disabling this will allow people to pay immediately for a membership that is available, and buy tickets to any events you have created"
                        checked={organisation.settings.mustConfirmNewMembers}
                        setChecked={(val: boolean) => {
                            setOrganisation({
                                ...organisation,
                                settings: {...organisation.settings, mustConfirmNewMembers: val}
                            })
                        }}/>*/}
                    <FormControl sx={{mt: 2}}>
                        <FormLabel>{t("Pick a color for your organisation")}</FormLabel>
                        <CssVarsProvider theme={getSliderTheme(color)}>
                            <Slider min={0} max={360} value={color}
                                    onChange={(_, v) => setColor(v as number)}/>
                        </CssVarsProvider>
                    </FormControl>
                </Grid>
            </Grid>
            <FormActions>
                <Button color={"danger"} onClick={() => navigate("/")} sx={{ml: 1}}>
                    <ExitToAppIcon sx={{mr: 2}}/> {t("Cancel")}
                </Button>
                <Button color={"success"} onClick={submit} sx={{ml: 1}}>
                    <SaveIcon sx={{mr: 2}}/> {t("Create organisation")}
                </Button>
            </FormActions>
        </FormCard>
    </>
}
