import {Card, CardContent, Grid} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import Divider from "@mui/joy/Divider";
import Avatar from "@mui/joy/Avatar";
import {getInitials, MemberInfo} from "../../types/ApplicationUser";
import GridInfoItem from "../organisation/members/GridMemberInfoItem";
import {dateString} from "../../utils/date";
import useTranslation from "../../hooks/contexts/TranslationContext";
import useScreen from "../../hooks/useScreen";

export default function BigOrgUserCard({user, orgName = undefined}: { user: MemberInfo, orgName?: string }) {
    const {t} = useTranslation()
    const {Phone, Tablet} = useScreen()

    return <Card>
        <CardContent>
            <Typography level={"title-lg"}>{user.fullName}</Typography>
            {orgName && <Typography level={"body-sm"}>{`${t("Member of")} ${orgName}`}</Typography>}
        </CardContent>
        <Divider/>
        <CardContent>
            <Grid container>
                {!(Phone || Tablet) && <Grid md={3}>
                    <Avatar color={"primary"}
                            sx={{fontSize: '4em', p: '1em', m: 3}}>
                        {getInitials(user)}
                    </Avatar>
                </Grid>}
                <Grid md={3}>
                    <GridInfoItem title={"Firstname"} value={user.firstName}/>
                    <GridInfoItem title={"National Registration Number"}
                                  value={user.nationalInsuranceNumber ?? ''}/>
                </Grid>
                <Grid md={3}>
                    <GridInfoItem title={"LastName"} value={user.lastName}/>
                    <GridInfoItem title={"Registered on"} value={dateString(user.registerDate)}/>
                </Grid>
                <Grid md={3}>
                    <GridInfoItem title={"Loyalty points"} value={`${user.points}`}/>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
}
